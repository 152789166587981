<template>
<div style="padding: 1rem 1rem;position: relative;background: #FAFAFD;">
  <div style="font-weight: 400;font-size: 20px;color: rgba(0,0,0,0.8);" class="d-flex flex-y-center flex-between">
      <div style="align-items: center;display: flex;">
        小说拆书
        <el-tooltip style="margin-left: 6px;" popper-class="custom-tooltip" effect="dark" content="本功能旨在通过拆解作品，帮助创作者更深入的学习优秀作品，提升自己的创作能力。我们坚决支持原创，保护原创，反对抄袭。" placement="right">
          <!-- <i class="el-icon-info"></i> -->
          <img src="../../assets/icon-info.png" style="width: 20px;" />
        </el-tooltip>
      </div>
      <el-button icon="el-icon-circle-plus-outline" class="mly-border-radius-12" style="padding: 0.5rem 2rem;margin-top: 1rem;margin-bottom: .5rem;" size="small" type="primary" @click="token!=''?setShowBreakdownDialogFlag(true):setShowLoginDialogFlag(true);setSuccessFlag(false)">上传新作品</el-button>
  </div>
  <!-- 筛选条件 -->
  <div class="">
    <span style="display: inline-block;margin-right: 10px;">
      <el-input suffix-icon="el-icon-search" class="search-title custom-select" v-model="queryForm.name" @input="handlePage()" placeholder="输入作品名称" clearable>
      </el-input>
    </span>
    <span style="">
      <el-select style="width: 6rem;" class="custom-select"
          v-model="queryForm.orderBy"
          filterable
          size="mini"
          placeholder="排序">
        <el-option
          v-for="(item,index) in [{name: 'create_time', desc: '创建时间'}, {name: 'update_time', desc: '最近操作时间'}]"
          @click.native.stop="handlePage()"
          :label="item.desc"
          :key="index"
          :value="item.name"
        />
      </el-select>
    </span>
  </div>
  <!-- 数据表格 -->
  <el-table
    :row-style="{height:'40px'}"
    :cell-style="{padding:'14px 0px'}"
    :header-cell-style="{background:'#FAFAFD',color:'rgba(0,0,0,0.4)','font-weight': 400, 'font-size': '14px'}"
    :data="tableData"
    :highlight-current-row="false"
    ref="tableContainer"
    class="table-container">
    <el-table-column
      prop="name"
      label="作品名称"
      class="max-width-column"
      show-overflow-tooltip>
      <template slot-scope="scope">
        {{scope.row.name?scope.row.name:''}}
      </template>
    </el-table-column>

    <el-table-column align="center"
      prop="fileSize"
      label="正文字数"
      min-width="100px"/>

    <el-table-column align="center"
      prop="zhanggangNumber"
      label="章节数"
      min-width="100px"/>

    <el-table-column align="center"
      prop="createTime"
      label="上传时间"
      min-width="100px"/>

    <el-table-column align="center"
      prop="updateTime"
      label="最后修改时间"
      min-width="100px"/>

    <el-table-column min-width="100px" label="操作" align="center">
      <template slot="header">
      </template>
      <template slot-scope="scope">
        <div class="d-flex" style="justify-content: space-evenly;">
          <el-tooltip style="margin-left: 6px;" popper-class="custom-tooltip" effect="dark" :content="scope.row.breakdownState=='COMPLETED'?'查看':'失败！AI网络异常'" placement="top">
            <div v-if="scope.row.targetType=='BOOK_BREAKDOWN' && scope.row.breakdownState=='COMPLETED'"
              style="margin: 0 4px;" class="image-switcher image-switcher-sousuo pointer" @click="handleChakan(scope.row)">
            </div>
            <div v-if="scope.row.targetType=='BOOK_BREAKDOWN' && scope.row.breakdownState!='COMPLETED'"
              style="margin: 0 4px;cursor: not-allowed;" class="image-switcher image-switcher-sousuo-hui">
            </div>
          </el-tooltip>
          <el-tooltip style="margin-left: 6px;" popper-class="custom-tooltip" effect="dark" content="编辑" placement="top">
            <div v-show="scope.row.targetType=='BOOK_BREAKDOWN_V2'" style="" class="image-switcher image-switcher-edit pointer" @click="rowClickEdit(scope.row)"></div>
          </el-tooltip>
          <el-tooltip style="margin-left: 6px;" popper-class="custom-tooltip" effect="dark" content="拆书" placement="top">
            <div v-show="scope.row.targetType=='BOOK_BREAKDOWN_V2'" style="margin: 0 4px;" class="image-switcher image-switcher-chaishu pointer" @click="rowClickBreakdown(scope.row)"></div>
          </el-tooltip>
          <el-tooltip style="margin-left: 6px;" popper-class="custom-tooltip" effect="dark" content="删除" placement="top">
            <div v-show="scope.row.targetType=='BOOK_BREAKDOWN_V2'" style="" class="image-switcher image-switcher-delete pointer" @click="handleRemove(scope.row)"></div>
          </el-tooltip>
        </div>
      </template>
    </el-table-column>

    <!-- empty -->
    <template v-slot:empty v-if="token == ''">
      <div class="data-empty">
        <img src="https://www.creatimix.com/static/empty.jpg" style="height: 80px;">
        <p>马上登录开始创作之旅!</p>
        <p style="margin: 0;color: rgba(0,0,0,0.6);font-size: 14px;">
          <el-button style="border-radius: 6px !important;padding: 0.5rem 2rem;margin-top: 1rem;margin-bottom: .5rem;" size="small" type="primary"  @click="setShowLoginDialogFlag(true)">注册/登录</el-button>
        </p>
      </div>
    </template>
  </el-table>

  <el-row class="pagination-fixed"  v-if="token != ''" :style="{ 'width': tableWidth + 'px'}">
    <el-col>
      <div class="pagination">
        <el-pagination background
          hide-on-single-page
          :page-sizes="paginations.pages"
          :page-size="paginations.size"
          :layout="paginations.layout"
          :total="paginations.total"
          :current-page.sync="paginations.current"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        />
      </div>
    </el-col>
  </el-row>

  <!-- 编辑 弹窗 -->
  <el-dialog :append-to-body="true" class="custom-dialog"
    title=""
    :visible.sync="showUpdateFlag"
    @close="close"
    style="text-align: center;margin: 0 auto;">
      <el-form ref="uploadFile" :model="uploadFile" :rules="rules">
        <el-form-item prop="name" label="作品名称">
           <el-input type="text" v-model="uploadFile.name"></el-input>
        </el-form-item>
        <div class="" style="margin-top: 3rem; margin-bottom: 1rem;">
          <el-button type="primary" class="custom-button mly-border-radius-12" style="" @click="handleUpdate">保存</el-button>
        </div>
      </el-form>
  </el-dialog>
  <BookBreakdownDialog :enums="enums" channel="bookbreakdown_index" />
  <BookBreakdownDetailDialog v-if="showBreakdownDetailDialogFlag" :enums="enums" :uploadFile="uploadFile" />
</div>
</template>
<script>
import * as EnumApi from '@/api/enums'
import * as FileApi from '@/api/file'
import BookBreakdownDialog from '@/components/BookBreakdownDialog/index'
import BookBreakdownDetailDialog from '@/components/BookBreakdownDialog/detail'
import { mapState, mapMutations} from 'vuex'
export default {
  name: 'Article',
  components: {
    BookBreakdownDialog,BookBreakdownDetailDialog
  },
  data() {
    return {
      tableWidth: 0,
      sliderBoxActive: 0,
      showUpdateFlag: false,
      fromType: 'writer_client',
      enums: {},
      intervalId: null,
      uploadFile: {},
    	targetPermit: 'article',
      queryForm: {
        targetTypes: ['BOOK_BREAKDOWN','BOOK_BREAKDOWN_V2'],
        fromType: 'writer-web',
        name: '',
        orderBy: '',
        current: '',
        size: 20
      },
      rules: {
        name: [
          { required: true, message: '请输入内容', trigger: 'blur' }
        ]
      },
      tableData: [],
      progressMap: new Map(),
      activeMenuItem: 1,
      paginations: {
        current: 1, // 当前页
        total: 0, // 总行数
        size: 20, // 每页行数
        // sizes: [20], // 每页行数 调整
        layout: 'total, prev, pager, next' // 翻页属性
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.tableWidth = this.$refs.tableContainer.$el.offsetWidth;
      console.log('tableWidth', this.tableWidth);
    })
    this.setActiveMenuItem('2')
    this.handleEnum()
    this.handlePage()

    // 清除定时器，停止周期性执行
    if (this.intervalId) {
      // 清除定时器，停止周期性执行
      clearInterval(this.intervalId);
    }
    //refrush
    // this.intervalId = setInterval(() => {
    //   // console.log('tableData:', this.tableData);
    //   let items = this.tableData.filter((item) => {return item.breakdownState == "SUBMITTED"})
    //   // console.log('items:', items);
    //   if(items.length > 0){
    //     this.handlePage()
    //   }
    // }, 1000);
  },
  computed: {
  	...mapState(['user', 'story', 'token', 'showBreakdownDetailDialogFlag'])
  },
  beforeDestroy() {
    // 清除定时器，停止周期性执行
    if (this.intervalId) {
      // 清除定时器，停止周期性执行
      clearInterval(this.intervalId);
    }
  },
  methods: {
    ...mapMutations(['setStory', 'setShowBreakdownDialogFlag', 'setEnums', 'setUploadFile','setSuccessFlag',
    'setShowLoginDialogFlag', 'setShowBreakdownDetailDialogFlag', 'setActiveMenuItem']),
    close(){
      this.showUpdateFlag = false
    },
    handleRemove(item) {
      this.$confirm('确定删除吗?','提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass: 'custom-confirm',
        center: false
      }).then(() => {
        FileApi.remove(item.id).then(res => {
          this.handlePage();
        });
      }).catch((e) => {console.log('error', e)});
    },
    handleProgress(data){
      let diff = (new Date().getTime() - new Date(data.createTime).getTime())/1000
      // let progress = Number(diff * this.getRandomInt(1,5)).toFixed(0)
      let progress = this.getRandomInt(1,20)
      // console.log('diff', diff, 'progress', progress)
      let pre = this.progressMap.get(data.id)
      if(pre){
        progress += pre.progress
        this.progressMap.set(data.id, {'progress': progress})
      }else{
        this.progressMap.set(data.id, {'progress': progress})
      }
      if(progress >= 100){
        progress = 97
      }
      data.progress = progress
    },
    getRandomInt(min, max) {
      min = Math.ceil(min); // 向上取整
      max = Math.floor(max); // 向下取整
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    handleEnum() {
      let that = this;
      EnumApi.list().then(res => {
        let result = res.data.data;
        that.enums = result
        this.setEnums(result)
      })
    },
    handleChakan(item) {
      this.rowClick(item)
      this.setShowBreakdownDetailDialogFlag(true)
    },
    rowClickEdit(item) {
      this.rowClick(item)
      this.showUpdateFlag = true
    },
    rowClickBreakdown(item) {
      this.rowClick(item)
      this.setUploadFile(item)
      setTimeout(()=>{
        this.$router.push('/workspace/chaishu')
      }, 300)
    },
    rowClick(item) {
      this.uploadFile = Object.assign({}, item)
    },
    handleUpdate(){
      console.log('edit name', this.uploadFile);
      this.$refs['uploadFile'].validate((valid) => {
        if(valid){
          FileApi.update({id: this.uploadFile.id, name: this.uploadFile.name, fromType: "chaishu-rename"}).then(res => {
            this.showUpdateFlag = false
            this.handlePage()
          })
        }
        console.log("validate >> stop, valid: " + valid)
      });
    },
    handlePage(current) {
      let that = this;
      if (current) {
        that.paginations.current = current
      }
      that.queryForm.current = that.paginations.current
      that.queryForm.size = that.paginations.size
      // console.log('workspace index user', this.user);
      if(!this.user || !this.user.id){
        return false;
      }
      that.queryForm.accountId = this.user.id
      // 获取表格数据
      FileApi.page(that.queryForm).then(res => {
        let result = res.data.data;

        that.tableData = result.records
        // that.tableData.forEach((item,index) => {
        //   that.handleProgress(item)
        // })
        that.paginations.total = Number(result.total)
        that.paginations.current = Number(result.current)
        that.paginations.size = Number(result.size)
      })
    },
    handleCurrentChange(page) {
      // 切换页码
      this.paginations.current = page
      this.handlePage()
    },
    handleSizeChange(size) {
      // 切换size
      this.paginations.size = size
      this.size = size
      this.handlePage()
    }
  }
}
</script>
<style scoped lang="scss">
::v-deep .el-tooltip__popper {
  max-width: 200px;
}
.pagination-fixed {
  position: fixed;
  bottom: 0;
  right: 0;
  margin-bottom: 1rem;
  margin-right: 3rem;
  background: #FAFAFD;
  padding: 1rem 0;
}
.custom-select {
    /deep/ .el-input__inner {
      border-radius: 20px;
      height: 1.5rem;
      line-height: 1.5rem;
    }
    /deep/ .el-input__suffix {
      height: 1.5rem;
      line-height: 1.5rem;
      font-size: 12px;
      display: flex;
      align-items: center;
    }
}
/* 取消行悬停效果 */
/deep/ .el-table--enable-row-hover {
    background-color: transparent !important;
}

/* 取消行过渡效果 */
/deep/ .el-table--enable-row-transition {
    transition: none !important;
}
/deep/ .el-table th.el-table__cell.is-leaf, /deep/ .el-table td.el-table__cell {
  border-bottom: none;
}
.table-container {
  margin-bottom: 1rem;
  /deep/ .el-table__body {
    border-spacing: 0 10px;
    border-radius: 20px;
    width: auto !important;
  }
  /deep/ .el-table__body-wrapper.is-scrolling-left{
    width: auto !important;
  }
  /deep/ .el-table__body-wrapper .el-table__empty-block {
    width: auto !important;
  }
}

::v-deep .el-table td:first-child {
  border-radius: 20px 0 0 20px;
  padding-left: 2rem;
}
::v-deep .el-table td:last-child {
  border-radius: 0 20px 20px 0;
}

::v-deep td.el-table__cell {
  color: rgba(0,0,0,0.8);
  background-color: transparent !important;
}
::v-deep .el-table .el-table__cell:first-child {
  font-weight: bold;
  padding-left: 2rem;
}
::v-deep .el-table__row:hover{
  background-color: #E5E7FC !important;
}
::v-deep .el-table__row {
  font-weight: 400;
  font-size: 14px;
  color: rgba(0,0,0,0.8);
}
.max-width-column .cell {
  max-width: 15rem; /* 设置最大宽度 */
  white-space: nowrap; /* 防止文本换行 */
  overflow: hidden; /* 隐藏超出部分 */
  text-overflow: ellipsis; /* 显示省略号 */
}

.data-empty {
  padding: 1rem;
  height: calc( 100vh - 8rem );
}
/deep/ .el-empty {

}
/deep/ .el-empty__bottom {
  margin-top: 10px;
}
/deep/ .el-table__empty-text {
  line-height: 0px !important;
}
.search-title {
  /deep/ .el-input__inner {
    height: 1.5rem;
    line-height: 1.5rem;
    font-size: 12px;
  }
  /deep/ .el-input__suffix {
    height: 1.5rem;
    line-height: 1.5rem;
    font-size: 12px;
    display: flex;
    align-items: center;
  }
}
/* CSS样式 */
.image-switcher {
  display: inline-block;
  width: 14px;
  height: 14px;
  background-size: cover;
  transition: background-image 0.3s; /* 平滑过渡效果 */
  &-sousuo-hui {
    background-image: url('../../assets/icon-sousuo.png'); /* 默认状态下的图片 */
  }
  &-sousuo {
    background-image: url('../../assets/icon-sousuo.png'); /* 默认状态下的图片 */
  }
  &-sousuo:hover {
    background-image: url('../../assets/icon-sousuo-1.png'); /* 默认状态下的图片 */
  }
  &-sousuo:active {
    background-image: url('../../assets/icon-sousuo.png'); /* 默认状态下的图片 */
  }

  &-edit {
    background-image: url('../../assets/icon-edit.png'); /* 默认状态下的图片 */
  }
  &-edit:hover {
    background-image: url('../../assets/icon-edit-1.png'); /* 默认状态下的图片 */
  }
  &-edit:active {
    background-image: url('../../assets/icon-edit.png'); /* 默认状态下的图片 */
  }

  &-chaishu {
    background-image: url('../../assets/icon-chaishu.png'); /* 默认状态下的图片 */
  }
  &-chaishu:hover {
    background-image: url('../../assets/icon-chaishu-1.png'); /* 默认状态下的图片 */
  }
  &-chaishu:active {
    background-image: url('../../assets/icon-chaishu.png'); /* 默认状态下的图片 */
  }

  &-delete {
    background-image: url('../../assets/icon-delete.png'); /* 默认状态下的图片 */
  }
  &-delete:hover {
    background-image: url('../../assets/icon-delete-1.png'); /* 默认状态下的图片 */
  }
  &-delete:active {
    background-image: url('../../assets/icon-delete.png'); /* 默认状态下的图片 */
  }
}

</style>
