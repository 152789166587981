import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import persistState from './plugins/persistState';

import 'normalize.css/normalize.css'

import ElementUI from 'element-ui'
import './styles/element-variables.scss'

import axios from './http'
import VueQuillEditor from 'vue-quill-editor'
import echarts from 'echarts'


import ElTreeGrid from 'element-tree-grid'
Vue.component(ElTreeGrid.name,ElTreeGrid);

import directives from '@/directives'
Vue.use(directives)

import QjTools from '@/plugins/global'
Vue.use(QjTools)

import CusImage from '@/components/Image'
Vue.component('cus-image', CusImage)

import SimpleTimeline from 'vue-simple-timeline';
Vue.use(SimpleTimeline);

import permits from '@/utils/permits'
import time from '@/utils/time'
Vue.prototype.$permits = permits
Vue.prototype.$echarts = echarts
Vue.prototype.$time = time
// require styles 引入样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import 'element-ui/lib/theme-chalk/display.css';

// 引入全局样式
import '@/styles/index.scss' // global css

// 引入excel插件
import './excel/Blob.js'
import './excel/Export2Excel.js'

// 引入打印插件
import Print from 'vue-print-nb'
Vue.use(Print) // 注册

Vue.use(ElementUI)
Vue.use(VueQuillEditor)

import VueVideoPlayer from 'vue-video-player'
// require videojs style
import 'video.js/dist/video-js.css'
// import 'vue-video-player/src/custom-theme.css'
Vue.use(VueVideoPlayer)

import MarqueeText from 'vue-marquee-text-component'
Vue.component('marquee-text', MarqueeText)

Vue.prototype.$axios = axios

Vue.config.productionTip = false
Vue.prototype.$eventHub = Vue.prototype.$eventHub || new Vue()

import 'animate.css'
import 'fullpage-vue/src/fullpage.css'
import VueFullpage from 'fullpage-vue'
Vue.use(VueFullpage)

import VueDragResize from 'vue-drag-resize'
Vue.component('vue-drag-resize', VueDragResize)

import Tabs from "vue-material-tabs";
Vue.use(Tabs);

//强制跳转 https
if (location.protocol !== 'https:' && location.host.indexOf('maliwriter') >= 0 ) {
  location.href = 'https:' + location.href.substring(location.protocol.length);
}

// console.log('persistState', store);
new Vue({
  router,
  store,
  mounted(){
    window.vue = this
    window.addEventListener('resize', this.updateMobile);
    // document.dispatchEvent(new Event('render-event'));
    document.dispatchEvent(new Event('custom-render-trigger'));
  },
  created() {
    // 使用插件
    // console.log('this.$store', this.$store);
    persistState(this.$store);
  },
  computed: {
    buttonSize(){
      return window.innerWidth < 768 ? 'mini' : 'medium'
    },
    isMobile() {
      return window.innerWidth < 768
    }
  },
  methods: {
    updateMobile() {
      this.$forceUpdate(); // 强制重新渲染，以更新计算属性
    }
  },
  render: h => h(App)
}).$mount('#app')
