<template>
    <!-- 拆书-查看 弹窗 -->
    <el-dialog
      class="custom-dialog new-task-detail-dialog"
      ref="dialog-detail"
      :visible.sync="showBreakdownDetailDialogFlagHe"
      :append-to-body="true"
      :modal-append-to-body="false"
      :close-on-press-escape="true"
      :withHeader="false"
      :destroy-on-close="true"
      :show-close="true"
      :close-on-click-modal="true"
      :wrapperClosable="true"
      :width="drawerWidth"
      :top="dialogTop"
      @close="closeDetail" >
        <div class="" style="margin-top: 1rem;">
          <h3 style="color: rgba(0,0,0,0.9);">拆书-{{uploadFile.name}}-{{storyTask.storyPrompt&&storyTask.storyPrompt.title}}</h3>
          <div class="detail-right mly-scrollbar" style="flex: 1;" v-html="replaceLineBreaks(breakdownResult)">

          </div>
          <div class="d-flex" style="justify-content: flex-end;margin-top: 10px;">
            <el-button style="border-radius: 10px !important;" class="" size="mini" type="primary" icon="el-icon-refresh" @click="handleRetry" plain>重新生成</el-button>
            <el-button style="border-radius: 10px !important;" class="" size="mini" type="primary" icon="el-icon-download" @click="downloadFile2" plain>下载</el-button>
            <el-button style="border-radius: 10px !important;" class="" size="mini" type="primary" icon="el-icon-copy-document" @click="copyTextToClipboard" plain>复制</el-button>

          </div>
        </div>
    </el-dialog>
</template>
<script>
import * as StorySubtaskApi from '@/api/storysubtask'
import * as StoryTaskApi from '@/api/storytask'
import { mapState, mapMutations} from 'vuex'
export default {
  name: 'BreakdownDetail',
  props: {
    enums: {
      type: Object,
      default: {ArticleTypeEnum: []}
    },
    storyTask: {
      type: Object,
      default: {}
    },
  },
  components: {

  },
  data() {
    return {
      treeData: [],
      breakdownResult: '',
    }
  },
  mounted() {
    console.log('breakdown detail');
    window.addEventListener('resize', this.forceUpdate);
    this.handlePageSubtask();
  },
  computed: {
    ...mapState(['user', 'uploadFile', 'showBreakdownDetailDialogFlagHe']),
    drawerWidth() {
      return window.innerWidth <= 768 ? '100%' : window.innerWidth <= 1200 ? '80%' : '60%';
    },
    dialogTop() {
      return window.innerWidth <= 768 ? "5vh" : '5vh';
    },
    dialogTitle () {
      return '拆书查看'
    },
    showHeader() {
      return window.innerWidth <= 768
    },
  },
  created() {
    console.log('created');
  },
  methods: {
    ...mapMutations(['setStory', 'setShowBreakdownDetailDialogFlagHe']),
    closeDetail(){
      this.setShowBreakdownDetailDialogFlagHe(false);
    },
    downloadFile2() {
      const filename = this.uploadFile.name
      const text = this.breakdownResult
      var FileSaver = require('file-saver');
      var blob = new Blob([text], {type: "text/plain;charset=utf-8"});
      // 下载文件，文件名：码哩写作拆书-故事标题-拆书类型，例如码哩写作拆书-拔剑十万次-故事要素.txt
      let downloadName = this.storyTask.storyPrompt&&this.storyTask.storyPrompt.title;
      FileSaver.saveAs(blob, "码哩写作拆书-"+filename+"-"+downloadName+".txt");
    },
    replaceLineBreaks(str) {
      return str?str.replace(/\\n/g, '\n').replace(/\n/g, '<br>'):"";
    },
    handleRetry() {// 重新生成，新建一个任务
      StoryTaskApi.update({id: this.storyTask.id, retryFlag: true, breakdownState: 'SUBMITTED', fromType: 'reCreateTask'}).then(res => {
        this.$parent.handlePageTask();
        this.closeDetail()
      });
    },
    handlePageSubtask(){
      let taskId = this.storyTask.id
      if(!taskId) {
        return false;
      }
      StorySubtaskApi.page({taskId, size: 5000}).then(res => {
        let result = res.data.data;
        console.log('uploadFile', this.uploadFile)
        if(res.data.code == 0){
          if(result.total > 0){
            let parent = {
              chapterTitle: this.uploadFile.name,
              id: '0'
            }
            this.$set(parent, 'children', result.records);
            this.treeData = []
            this.treeData.push(parent)
            // result
            // 使用 map 方法提取所有的 'breakdownResult' 值
            const breakdownResults = result.records.map(item => item.breakdownResult);
            console.log('breakdownResults', breakdownResults)
            // 使用 join 方法将提取的值拼接成一个字符串
            this.breakdownResult = breakdownResults.join('\n');
          }else{
            console.log('无章节数据')
          }
        }
      })
    },
    forceUpdate() {
      this.$forceUpdate(); // 强制重新渲染，以更新计算属性
    },
    copyTextToClipboard() {
      const textArea = document.createElement('textarea');
      textArea.value = this.breakdownResult;
      textArea.style.position = 'fixed';  // 防止出现滚动条
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        const successful = document.execCommand('copy');
        if (successful) {
          console.log('成功复制到剪贴板');
          this.$message.success('复制成功');
        } else {
          this.$message.error('复制失败');
          console.error('复制失败');
        }
      } catch (err) {
        this.$message.error('复制时出错');
        console.error('复制时出错: ', err);
      }
      document.body.removeChild(textArea);
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.forceUpdate);
  },
}
</script>

<style scoped lang="scss">
/deep/ .el-dialog__header {
  padding: 0;
}
/deep/ .el-form--label-top .el-form-item__label {
  padding: 0;
  font-weight: normal;
}
/deep/ .vmt__nav__items .vmt__nav__item{
  font-size: 14px;
}
.tab-item-content {
  margin-top: 1rem;
  margin-bottom: 1rem;
  height: 50vh;
  background: rgba(206,208,218,0.19);
  border-radius: 16px 16px 16px 16px;
  padding: 1rem;
  font-size: 14px;
  line-height: 28px;
  text-align: justify;
  overflow-y: auto;
  overflow-x: hidden;
}
.tab-item-copy {
  padding: .5rem 1rem;
  color: #0052D9;
  border-radius: 6px;
  width: 4rem;
  float: right;
  border: 1px solid #0052D9;
}
.tab-item-copy:hover {
  background-color: rgba(0, 0, 0, 0.11);
}
/* 全局滚动条轨道的padding */
.mali-scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
  // padding: 20px 0; /* 上下padding */
  margin: 1rem 0;
}

/* 滚动条的样式 */
.mali-scrollbar::-webkit-scrollbar {
  width: 8px;
  margin: 1rem 0;
  background-color: transparent;
}

.mali-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
}
.detail-right {
  background: rgba(206,208,218,0.1);
  border-radius: 16px 16px 16px 16px;
  padding: 1rem;

  line-height: 1.5rem;
  overflow-y: scroll;
  height: 80vh;
}
</style>
