<template>
    <!-- 拆书 弹窗 -->
    <el-dialog
      class="custom-dialog new-changpian-dialog"
      ref="dialog"
      :visible.sync="showBreakdownDialogFlag"
      :append-to-body="true"
      :modal-append-to-body="false"
      :close-on-press-escape="true"
      :withHeader="false"
      :show-close="true"
      :close-on-click-modal="true"
      :wrapperClosable="true"
      :destroy-on-close="true"
      :width="drawerWidth"
      :top="dialogTop"
      @close="close"
      >
      <el-form label-position="top">
        <el-row type="flex" align="middle">
          <h3 style="color: rgba(0,0,0,0.9);">{{dialogTitle}}</h3>
        </el-row>

        <el-form-item prop="url" label="">
          <div class="icon-bg-outer">
            <div class="icon-bg">
              <div style="font-size: 12px;line-height: 18px;padding-left: 12px;background: transparent;">上传内容前，请务必删除与作品正文无关的内容，如<span style="color: #0052D9;">作者有话说、弹幕等</span>，避免影响拆书质量！</div>
            </div>
          </div>
          <ly-pic-uploader ref="lyuploader" :url="formData.url"></ly-pic-uploader>
        </el-form-item>
      </el-form>
     <div type="flex" justify="center" style="display: flex;justify-content: center;" v-if="successFlag">
       <el-button class="mly-border-radius-12" style="padding: 10px 55px;font-size: 12px;" type="primary" @click="onNextStep">确认下一步</el-button>
     </div>
    </el-dialog>
</template>
<script>
import { Loading } from 'element-ui'
import { mapState, mapMutations} from 'vuex'
import * as FileApi from '@/api/file'
import LyPicUploader from '@/components/PicUploader/index_chaishu'
export default {
  name: 'BookBreakdownOk',
  props: {
    enums: {
      type: Object,
      default: {ArticleTypeEnum: []}
    },
    channel: {
      type: String,
      default: 'bookbreakdown_index'
    },
  },
  components: {
    LyPicUploader
  },
  data() {
    return {
      formData: {
        fromType: 'ok_file',
        targetType: 'BOOK_BREAKDOWN_V2',
        breakdownTypes: [],
        name: '',
        url: ''
      },
      showZhanggangNumberFlag: false,
      costEnergy: '-',
      rules: {
        url: [
          { required: true, message: '请选择文件', trigger: 'blur' }
        ],
      }
    }
  },
  mounted() {
    console.log('breakdown dialog mounted');
    window.addEventListener('resize', this.forceUpdate);
  },
  computed: {
    ...mapState(['user', 'uploadFile', 'showBreakdownDialogFlag', 'successFlag']),
    drawerWidth() {
      return window.innerWidth <= 768 ? '98vw' : window.innerWidth <= 1200 ? '45%' : '45%';
    },
    dialogTop() {
      return window.innerWidth <= 768 ? "4vh" : '10vh';
    },
    dialogTitle () {
      return '上传新作品'
    },
    showHeader() {
      return window.innerWidth <= 768
    },
    btnActiveFlag() {
      return (this.formData.articleGenType && this.formData.articleGenType.length>0)
      && (this.formData.breakdownTypes && this.formData.breakdownTypes.length>0)
      && (this.formData.url && this.formData.url.length>0)
    }
  },
  created() {
    console.log('created');
  },
  methods: {
    ...mapMutations(['setUploadFile', 'setShowBreakdownDialogFlag']),
    close(){
      this.resetData()
      this.setShowBreakdownDialogFlag(false);
    },
    onNextStep() {
      this.getFile()
      if (!this.formData.url || this.formData.url.length == 0) {
          this.$message.error('请上传作品');
          return false;
      }
      let params = Object.assign({}, this.formData)
      let loading = Loading.service({
        lock: true,
        text: '加载中...',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      FileApi.create(params).then(res => {
         console.log('ok file', res);
         this.setUploadFile(res.data.data)
         this.$parent.handlePage(1);
         if(res.data.code == 0){
           setTimeout(()=>{
             loading.close()
             this.close()
             this.$router.push('/workspace/chaishu');
            }, 300)
         }else{
           loading.close()
           this.$message.error(res.data.msg);
         }
      });
    },
    resetData(){
      this.formData.breakdownTypes = []
      this.formData.url = ''
    },
    removeFileExtension(filename) {
      // 使用正则表达式找到最后一个点（.）的位置
      const lastDotIndex = filename.lastIndexOf('.');

      // 如果没有找到点，或者点是文件名的第一个字符（隐藏文件），则返回原文件名
      if (lastDotIndex === -1 || lastDotIndex === 0) {
        return filename;
      }

      // 返回从开始到点之前的所有字符
      return filename.substring(0, lastDotIndex);
    },
    getFile(){
      const file = this.$refs.lyuploader.getFile()
      console.log('getFile = ', file);
      // this.formData.url = file.url
      // this.formData.name = file.name
      this.$set(this.formData, 'url', file.url);
      this.$set(this.formData, 'name', this.removeFileExtension(file.name));
    },
    removeFile(){
      console.log('removeFile = ', file);
      // this.formData.url = ''
      // this.formData.name = ''
      this.$set(this.formData, 'url', '');
      this.$set(this.formData, 'name', '');
    },
    getEnergy() {
      const file = this.$refs.lyuploader.getFile()
      console.log('file: ', file);

      if (!file.url || file.url.length == 0) {
          // this.$message.error('请上传作品');
          this.costEnergy = '-'
          return false;
      }
      if(this.formData.breakdownTypes.length == 0){
        this.costEnergy = '-'
        return false;
      }
      FileApi.create({url: file.url, fromType: 'energy', breakdownTypes: this.formData.breakdownTypes }).then(res => {
         console.log('get energy', res);
         const result = res.data.data;
         this.costEnergy = result.energy
         // this.$parent.updateEnergy()
      });
    },
    forceUpdate() {
      this.$forceUpdate(); // 强制重新渲染，以更新计算属性
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.forceUpdate);
  },
}
</script>

<style scoped lang="scss">
::v-deep .el-dialog__header {
  padding: 0;
}
::v-deep .el-form--label-top .el-form-item__label {
  padding: 0;
  font-weight: normal;
}
.custom-checkbox {
  margin-right: 1rem !important;
  margin-bottom: .5rem !important;
  border: none !important;
  ::v-deep .el-checkbox-button__inner {
    border: none !important;
    background: #F2F3FF;
    border-radius: 20px;
    padding: 6px 30px;
    color: #242424;
    font-size: 14px;
    font-weight: 400;
  }

}
::v-deep .el-checkbox-button:first-child .el-checkbox-button__inner {
  border-radius: 20px;
}

::v-deep .el-checkbox-button:last-child .el-checkbox-button__inner {
  border-radius: 20px;
}
::v-deep .el-checkbox-button.is-checked .el-checkbox-button__inner {
  background-color: #366EF4 !important;
  border-color: #366EF4 !important;
  box-shadow: none;
}
::v-deep .el-form-item__label {
  color: rgba(0, 0, 0, .8);
}
.icon-bg-outer {
  background: #F5F5FE;
  border-radius: 6px;
  margin-bottom: 8px;
  padding: 6px;
}
.icon-bg {
  background-image: url('../../assets/icon-tips-lite.png');
  background-repeat: no-repeat;
  background-position: 0px 2px;
  background-size: 12px 12px;
}
</style>
<style>
.el-message-box.custom-message-box {
  border-radius: 20px !important;
  padding: .5rem;
  padding-bottom: 1rem;
}
.el-button {
  border-radius: 6px !important;
}
</style>
