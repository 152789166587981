<template>
<div style="padding: 1rem 1rem;">
  <div class="d-flex flex-between flex-y-center writer-energy-box" style="">
    <div class="writer-energy-box-title" style="">
      能量明细
    </div>
    <div class="writer-energy-box-right" style="">
      <span class="writer-energy-box-right-item" :class="{'writer-energy-box-right-item-active': sliderBoxActive == 0}" style="" @click="itemClick(0)">
        获取
      </span>
      <span class="writer-energy-box-right-item" :class="{'writer-energy-box-right-item-active': sliderBoxActive == 1}" style="" @click="itemClick(1)">
        消耗
      </span>
    </div>
  </div>
  <!-- 数据表格 -->
  <el-table
    :header-cell-style="{background:'#E5EEFE', color:'rgba(0,0,0,0.4)','font-weight': 400}"
    :row-style="rowStyle"
    :data="tableData"
    :highlight-current-row="false"
    @cell-mouse-enter="cellMouseEnter"
    ref="tableContainer"
    class="table-container">

    <el-table-column
      prop="createTime"
      label="时间"
      width="200"/>
    <el-table-column v-if="sliderBoxActive==0"
      prop="profitTypeLabel"
      label="类型"
      width="250" />
    <el-table-column v-else
      prop="workflowNodeTitle"
      label="类型"
      show-overflow-tooltip
      min-width="100" />

    <el-table-column v-if="sliderBoxActive==0"
      prop="amount"
      label="获取能量"
      width="100" >
      <template slot-scope="scope">
        <span style="">
          {{scope.row.amount}}
        </span>
      </template>
    </el-table-column>
    <el-table-column v-else
      prop="amount"
      label="消耗能量"
      width="80" >
      <template slot-scope="scope">
        <span style="">
          {{scope.row.amount}}
        </span>
      </template>
    </el-table-column>
    <el-table-column min-width="40px" v-if="sliderBoxActive==1" label="备注" align="center">
      <template slot="header">
      </template>
      <template slot-scope="scope">
        <el-button @click="rowClick(scope.row)" size="mini" type="primary" round plain>详情</el-button>
      </template>
    </el-table-column>
    <el-table-column v-else
      prop="remark"
      label="备注"
      min-width="40px" />

  </el-table>
<!--  <el-empty v-if="tableData.length==0" image="https://www.creatimix.com/static/empty.jpg" description="还没有购买记录" style="margin: 0 auto;">
    <p style="margin: 0;color: rgba(0,0,0,0.6);font-size: 14px;">Start your journey!</p>
  </el-empty> -->

  <el-row>
    <el-col>
      <div class="pagination">
        <el-pagination background
          hide-on-single-page
          :page-sizes="paginations.pages"
          :page-size="paginations.size"
          :layout="paginations.layout"
          :total="paginations.total"
          :current-page.sync="paginations.current"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        />
      </div>
    </el-col>
  </el-row>

  <WorkflowNodeForm v-if="control.show" :control="control" :enums="enums"/>
</div>
</template>
<script>
import * as AccounntProfitLogApi from '@/api/accountprofitlog'
import { mapState, mapMutations} from 'vuex'
import WorkflowNodeForm from './form'
export default {
  name: 'Energy',
  components: {
    WorkflowNodeForm
  },
  data() {
    return {
      sliderBoxActive: 0,
      fromType: 'writer_client',
    	targetPermit: 'profit',
      queryForm: {
        incomeFlag: true,
        fromType: 'writer-web',
        current: '',
        size: 20
      },
      control: {
        show: false,
        title: '',
        action: this.$permits.VIEW,
        form: '',
        disabled: false
      },
      enums: [],
      tableData: [],
      paginations: {
        current: 1, // 当前页
        total: 0, // 总行数
        size: 20, // 每页行数
        // sizes: [20], // 每页行数 调整
        layout: 'total, prev, pager, next' // 翻页属性
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
    })
    this.handlePage()
  },
  computed: {
  	...mapState(['user', 'story'])
  },
  methods: {
    ...mapMutations(['setStory', 'setShowDialogFlag']),
    handleRemove(item) {
      this.$confirm('', '确定删除吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(() => {
        OrderApi.remove(item.id).then(res => {
          this.handlePage();
        });
      }).catch((e) => {console.log('error', e)});
    },
    rowStyle(){
      return {}
      // if(this.sliderBoxActive == 1){
      //   return {cursor: 'pointer'}
      // }else{
      //   return {}
      // }
    },
    rowClick(row, column, event){
      if(this.sliderBoxActive == 0){
        return false
      }
      console.log('row', row, column, event);
      this.control.show = true
      this.control.form = row
    },
    cellMouseEnter(row, column, cell, event){

    },
    itemClick(index){
      this.sliderBoxActive = index
      this.tableData = []
      this.handlePage()
    },
    handlePage(current) {
      let that = this;
      if (current) {
        that.paginations.current = current
      }
      that.queryForm.incomeFlag = that.sliderBoxActive == 0
      that.queryForm.current = that.paginations.current
      that.queryForm.size = that.paginations.size
      console.log('workspace index user', this.user);
      if(!this.user || !this.user.id){
        return false;
      }
      that.queryForm.accountId = this.user.id
      // 获取表格数据
      AccounntProfitLogApi.page(that.queryForm).then(res => {
        let result = res.data.data;
        that.tableData = result.records
        that.paginations.total = Number(result.total)
        that.paginations.current = Number(result.current)
        that.paginations.size = Number(result.size)
      })
    },
    handleCurrentChange(page) {
      // 切换页码
      this.paginations.current = page
      this.handlePage()
    },
    handleSizeChange(size) {
      // 切换size
      this.paginations.size = size
      this.size = size
      this.handlePage()
    }
  }
}
</script>

<style scoped lang="scss">

/* 取消行悬停效果 */
/deep/ .el-table--enable-row-hover {
    background-color: transparent !important;
}

/* 取消行过渡效果 */
/deep/ .el-table--enable-row-transition {
    transition: none !important;
}
/deep/ .el-table th.el-table__cell.is-leaf, /deep/ .el-table td.el-table__cell {
  border-bottom: none;
}
/deep/ td.el-table__cell {
  background-color: transparent !important;
}
// .el-table--enable-row-transition
/deep/ .el-table__row:hover{
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
  background-color: #366EF4 !important;
}
/deep/ .el-button--mini.is-round {
    padding: 5px 15px;
}
// .writer-energy-box
.writer-energy-box{
  margin-bottom: 1rem;
  &-title {
    font-weight: 400;
    font-size: 20px;
    color: rgba(0,0,0,0.8);
  }
  &-right{
    background: #E5EEFE;
    border-radius: 20px;
    color: rgba(0,0,0,0.9);
    font-size: .8rem;
    line-height: 1.8rem;
    &-item {
      padding: .25rem 1rem;
      cursor: pointer;
      &-active{
        background: #0052D9;
        color: rgba(255,255,255,0.9);
        border-radius: 20px;
      }
    }
  }
}

.table-container {
  margin-bottom: 1rem;
  /deep/ .el-table__body {
    border-spacing: 0 10px !important;
    border-radius: 20px;
    width: auto !important;
  }
  /deep/ .el-table__body-wrapper.is-scrolling-left{
    width: auto !important;
  }
  /deep/ .el-table__body-wrapper .el-table__empty-block {
    width: auto !important;
  }
}
</style>
