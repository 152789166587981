<template>
  <!-- 新建/编辑 -->
    <el-drawer
        :title="control.title"
        :enums="enums"
        :visible.sync="control.show"
        direction="rtl"
        :append-to-body="true"
        :modal-append-to-body="false"
        :close-on-press-escape="true"
        :withHeader="true"
        :destroy-on-close="true"
        :show-close="true"
        :size="drawerWidth"
        @close="close"
        >
        <!-- 故事 -->
        <div class="worknode-info" v-if="form.storyId && form.storyId != '0'">
          <div class="worknode-info-item">
            <span class="worknode-info-item-text">故事：</span><span>{{form.story?form.story.title:''}}</span>
          </div>
          <div class="worknode-info-item">
            <span class="worknode-info-item-text">故事ID：</span><span>{{form.storyId}}</span>
          </div>
          <div class="worknode-info-item">
            <span class="worknode-info-item-text">类型：</span><span>{{form.title}}</span>
          </div>
          <div class="worknode-info-item">
            <span class="worknode-info-item-text">生成结果：</span><br><span v-html="replaceLineBreaks(form.answer)"></span>
          </div>
        </div>
        <!-- 拆书 -->
        <div class="worknode-info" v-if="form.uploadFileId  && form.uploadFileId != '0'">
          <div class="worknode-info-item">
            <span class="worknode-info-item-text">作品：</span><span>{{form.content}}</span>
          </div>
          <div class="worknode-info-item">
            <span class="worknode-info-item-text">拆文ID：</span><span>{{form.uploadFileId}}</span>
          </div>
          <div class="worknode-info-item">
            <span class="worknode-info-item-text">类型：</span><span>{{form.title}}</span>
          </div>
          <div class="worknode-info-item">
            <span class="worknode-info-item-text">生成结果：</span><br><span v-html="replaceLineBreaks(form.answer)"></span>
          </div>
        </div>
        <!-- 拆书 v2 -->
        <div class="worknode-info" v-if="form.storyId == '0' && form.uploadFileId == '0'">
          <div class="worknode-info-item">
            <span class="worknode-info-item-text">作品：</span><span>{{form.content}}</span>
          </div>
          <div class="worknode-info-item">
            <span class="worknode-info-item-text">拆文ID：</span><span>{{form.uploadFileId}}</span>
          </div>
          <div class="worknode-info-item">
            <span class="worknode-info-item-text">类型：</span><span>{{form.title}}</span>
          </div>
          <div class="worknode-info-item">
            <span class="worknode-info-item-text">生成结果：</span><br><span v-html="replaceLineBreaks(form.answer)"></span>
          </div>
        </div>
    </el-drawer>
</template>

<script>
import * as WorkflowNodeApi from '@/api/workflownode'

export default {
  name: 'WorkflowNodeForm',
  props: {
    enums: '',
    control: Object
  },
  data() {
    return {
      form: {

      },
    }
  },
  computed: {
    drawerWidth() {
      return window.innerWidth <= 768 ? '100%' : '40%';
    },
    showHeader() {
      return window.innerWidth <= 768
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init(){
      let that = this;
      WorkflowNodeApi.view(that.control.form.workflowNodeId).then(res => {
        let result = res.data;
        that.form = result.data;
      })
    },
    close(){
      this.control.show = false;
    },
    replaceLineBreaks(str) {
      return str?str.replace(/\\n/g, '\n').replace(/\n/g, '<br>'):"";
    },
  }
}
</script>

<style scoped lang="scss">
.worknode-info {
  padding: 1rem;
  &-item {
    line-height: 1.5rem;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    &-text{
      color: rgba(0,0,0,0.4);
    }
  }
}
/deep/ .el-drawer__header {
  padding: 0;
  margin: 0;
}
</style>
