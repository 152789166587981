<template>
  <div class="writer-box writer-bg">
    <HeadNavWriter ref="headeNvWriterRef"/>

    <div class="writer-box-content" style="position: relative;">

      <!-- 头部 -->
      <div class="d-flex flex-y-center flex-between">
        <div class="" style="padding: 0.3rem 1rem 0 0;">
          <span style="color: #125FDC;font-size: 14px;" class="pointer" @click="$router.push('/bookbreakdown/index')">拆书学习</span>
          <i class="el-icon-arrow-right"></i>
          <span style="font-weight: 600;font-size: 16px;color: rgba(0,0,0,0.9);">{{uploadFile.name}}</span>
        </div>
        <div class="" style="">

        </div>
      </div>
<!-- hidden-xs-only -->
      <div class="writer-box-content-work d-flex" style="padding-bottom: .6rem;">
        <div class="writer-box-content-work-outline d-flex" style="flex-direction: column;min-width: 300px;" v-show="!hideFlagML">
            <div class="mly-fuzhuwenzi" style="">
              选择拆书章节（共{{uploadFile.zhanggangNumber}}章）
            </div>
            <div class="outline-list-box mly-border-radius-20 mly-scrollbar" style="flex: 1;">

              <div class="d-flex flex-between" style="margin-right: 1rem;padding-bottom: .5rem;">
                  <el-button style="border-radius: 10px !important;" class="" size="mini" type="primary" @click="getTopLevelFirstFiveNodesAndCheck" plain>前5章</el-button>
                  <el-button style="border-radius: 10px !important;" class="" size="mini" type="primary" @click="getNextFiveNodesAfterKey" plain>+5章</el-button>
                  <el-button style="border-radius: 10px !important;" class="" size="mini" type="primary" @click="selectAll" plain>全选</el-button>
                  <el-button style="border-radius: 10px !important;" class="" size="mini" type="primary" @click="clearAll" plain>重置</el-button>
                  <el-button v-if="isMobile" style="border-radius: 10px !important;" class="" size="mini" type="primary" @click="historyTask" plain>历史任务</el-button>
              </div>

              <el-tree
                ref="tree"
                :data="treeData"
                show-checkbox
                node-key="id"
                default-expand-all
                :check-strictly="true"
                :highlight-current="true"
                @check-change="handleCheckChange"
                @node-click="handleNodeClick">
                <div class="custom-tree-node d-flex flex-y-center flex-between"
                  v-on:hover="nodeHover"
                  style="width: 100%;font-weight: 400;margin-right: 1rem;font-size: 12px;color: rgba(0,0,0,0.6);"
                  slot-scope="{ node, data }">
                    <span style="width: 120px;color: rgba(0,0,0,0.9);" class="mly-ellipsis" :class="{'el-icon-document': node.level==2, 'el-icon-files': node.level==1}" >
                      {{ data?data.title:'' }}
                    </span>

                    <span style="color: rgba(0,0,0,0.4);" >
                      {{node.level==2?(node.data.wordNumber)+'字':node.childNodes.length+'章'}}
                    </span>
                </div>
              </el-tree>

            </div>

            <!-- 数据 -->
            <div class="mly-fuzhuwenzi" style="">
              已选 {{selectedChapterCount}}/{{uploadFile.zhanggangNumber}} 章，共 {{selectedWordCount}} 字
            </div>

            <!-- 拆书分类 -->
            <div class="" style="margin-bottom: 20px;">
              <div class="" style="margin: .5rem 0;">
                <span class="field-required-pre">*</span><span class="field-title" style="color: rgba(0,0,0,0.8);">拆书类型</span>
              </div>
              <el-select style="width: 100%;" class="custom-select"
                  v-model="queryForm.storyPromptId"
                  clearable
                  @change="handleChangePrompt"
                  size="mini"
                  placeholder="拆书类型">
                <el-option
                  v-for="(item,index) in breakdownPrompts"
                  :label="item.title"
                  :key="index"
                  :value="item.id"
                />
              </el-select>
            </div>
            <div class="icon-bg-outer" v-if="promptDesc != ''">
              <div class="icon-bg">
                <div v-html="promptDesc" style="font-size: 12px;line-height: 18px;font-size: 12px;color: rgba(0,0,0,0.6);background: transparent;padding-left: 18px;">

                </div>
              </div>
            </div>

            <!-- 表单字段 -->
            <div class="" style="margin: 1rem 0;" v-for="(item,index) in storyVars" :key="index">
              <div class="" style="margin: .5rem 0;">
                <span v-if="storyVars[index]['requiredFlag']" class="field-required-pre">*</span><span class="field-title" style="color: rgba(0,0,0,0.8);">{{storyVars[index]['fieldLabel']}}</span>
              </div>
              <div class="">
                  <!-- number -->
                  <el-input class="custom-zidingyi" v-show="storyVars[index]['fieldType']=='NUMBER'"
                    type="number"
                    :name="storyVars[index]['name']"
                    v-model="storyVars[index]['value']"
                    :min="0"
                    style="">
                  </el-input>

                  <!-- text -->
                  <el-input class="custom-zidingyi" v-show="storyVars[index]['fieldType']=='TEXT'"
                    type="text"
                    :name="storyVars[index]['name']"
                    v-model="storyVars[index]['value']"
                    :maxlength="storyVars[index]['maxLength']"
                    style="">
                  </el-input>

                  <!-- textarea -->
                  <el-input class="custom-zidingyi" v-show="storyVars[index]['fieldType']=='TEXTAREA'"
                    type="textarea" :rows="2"
                    :name="storyVars[index]['name']"
                    v-model="storyVars[index]['value']"
                    :maxlength="storyVars[index]['maxLength']"
                    style="" show-word-limit clearable>
                  </el-input>

                  <!-- list -->
                  <el-select style="width: 100%;" class="custom-select"
                      v-show="storyVars[index]['fieldType']=='SELECT'"
                      :name="storyVars[index]['name']"
                      v-model="storyVars[index]['value']"
                      clearable
                      size="mini"
                      placeholder="请选择">
                    <el-option
                      v-for="(item,index) in JSON.parse(storyVars[index]['fieldValue'])"
                      :label="item.name"
                      :key="index"
                      :value="item.value"
                    />
                  </el-select>
              </div>
            </div>

            <!-- 提交拆书 -->
            <div class="d-flex flex-y-center" style="">
              <img src="../../assets/icon-chaishu-desc.png" @click="goHelp" style="height: 32px;display: inline-block;margin-right: 8px;cursor: pointer;"/>
              <el-button style="flex: 1;border-radius: 10px !important;float: right;" class="" size="small"
               type="primary" @click="createTask"
               :isLoading="isLoading"
               :disabled="isLoading">提交拆书</el-button>
            </div>
            <div class="mly-fuzhuwenzi" style="">
              * 由于算力限制，最多可以同时有2个进行中的任务
            </div>
        </div>
        <!-- </div> -->

        <div class="writer-box-content-work-editor" style="" v-show="!hideFlagZW">
          <div style="font-weight: 400;margin-bottom: 1rem;
              line-height: 30px;
              font-size: 14px;
              color: #366EF4;">
            <el-button v-if="isMobile" style="border-radius: 10px !important;" class="" size="mini" type="primary" @click="checkedChapter" plain>所选章节</el-button>
            <span v-else style="">历史任务</span>
            <el-button style="border-radius: 10px !important;float: right;" class="" size="mini" type="primary" @click="shuaxin" plain>刷新</el-button>
          </div>

          <!-- 历史任务 -->
          <div class="" style="height: 100%;">

            <!-- 数据表格 -->
            <el-table
              :row-style="{height:'40px'}"
              :cell-style="{padding:'14px 0px'}"
              :header-cell-style="{background:'#FAFAFD',color:'rgba(0,0,0,0.4)','font-weight': 400, 'font-size': '14px'}"
              :data="tableData"
              :highlight-current-row="false"
              ref="tableContainer"
              class="table-container">
              <el-table-column
                prop="breakdownType"
                label="拆书类型"
                class="max-width-column"
                show-overflow-tooltip>
                <template slot-scope="scope">
                  {{scope.row.storyPrompt.title}}
                </template>
              </el-table-column>

              <el-table-column align="center"
                prop="zhanggangNumber"
                label="所属章节"
                class="max-width-column"
                show-overflow-tooltip>
                <template slot-scope="scope">
                  {{scope.row.chapterTitle}}
                </template>
              </el-table-column>

              <el-table-column align="center"
                prop="createTime"
                label="提交时间" />

              <el-table-column align="center"
                prop="breakdownState"
                label="拆书进度"
                 >
                <template slot-scope="scope">
                  <span :class="{'mly-color-paidui': scope.row.breakdownState=='SUBMITTED',
                   'mly-color-jinxing': scope.row.breakdownState=='RUNNING',
                   'mly-color-wancheng': scope.row.breakdownState=='COMPLETED',
                   'mly-color-zhongzhi': scope.row.breakdownState=='TERMINAL',
                   'mly-color-shibai': scope.row.breakdownState=='FAILED', }">
                    {{scope.row.breakdownStateLabel}}
                  </span>
                </template>
              </el-table-column>

              <el-table-column show-overflow-tooltip label="操作" align="center">
                <template slot="header">
                </template>
                <template slot-scope="scope">
                  <div class="d-flex" style="justify-content: space-evenly;">
                    <el-tooltip style="margin-left: 6px;" popper-class="custom-tooltip" effect="dark" content="终止" placement="top">
                      <div v-show="scope.row.breakdownState=='SUBMITTED'" style="" class="image-switcher image-switcher-zhongzhi pointer" @click="handleZhongzhi(scope.row)"></div>
                    </el-tooltip>
                    <el-tooltip style="margin-left: 6px;" popper-class="custom-tooltip" effect="dark" content="失败章节重试" placement="top">
                      <div v-show="scope.row.breakdownState=='FAILED'" style="" class="image-switcher image-switcher-shuaxin pointer" @click="handleRetry(scope.row)"></div>
                    </el-tooltip>
                    <el-tooltip style="margin-left: 6px;" popper-class="custom-tooltip" effect="dark" content="查看" placement="top">
                      <div v-show="scope.row.breakdownState!='SUBMITTED'" style="margin: 0 4px;" class="image-switcher image-switcher-sousuo pointer" @click="handleChakan(scope.row)"></div>
                    </el-tooltip>
                    <el-tooltip style="margin-left: 6px;" popper-class="custom-tooltip" effect="dark" content="删除" placement="top">
                      <div v-show="scope.row.breakdownState=='SUBMITTED'" style="" class="image-switcher image-switcher-delete pointer" @click="handleRemoveTask(scope.row)"></div>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>

              <!-- empty -->
              <template v-slot:empty>
                <div class="mly-data-empty">
                  <img src="../../assets/empty-pic.png" style="height: 200px; opacity: 0.8;">
                  <p>还没有历史记录，快开始拆书学习吧～</p>
                </div>
              </template>
            </el-table>

            <el-row class="pagination-fixed" :style="{ 'width': '100%'}">
              <el-col>
                <div class="pagination">
                  <el-pagination background
                    hide-on-single-page
                    :page-sizes="paginations.pages"
                    :page-size="paginations.size"
                    :layout="paginations.layout"
                    :total="paginations.total"
                    :current-page.sync="paginations.current"
                    @current-change="handleCurrentChange"
                    @size-change="handleSizeChange"
                  />
                </div>
              </el-col>
            </el-row>
          </div>

        </div>

      </div>
    </div>

  <BookBreakdownDetailDialogFen v-if="showBreakdownDetailDialogFlagV2" :enums="enums" :storyTask="storyTask" />
  <BookBreakdownDetailDialogHe v-if="showBreakdownDetailDialogFlagHe" :enums="enums" :storyTask="storyTask" />
  </div>
</template>
<script>
import HeadNavWriter from '@/components/HeadNavWriter'
import BookBreakdownDetailDialogFen from '@/components/BookBreakdownDialog/detail_fen'
import BookBreakdownDetailDialogHe from '@/components/BookBreakdownDialog/detail_he'
import LlmSkeleton from '@/components/LlmSkeleton'
import WriteTips from '@/components/WriteTips'
import * as EnumApi from '@/api/enums'
import * as ArticleApi from '@/api/article'
import * as ChapterApi from '@/api/chapter'
import * as StoryTaskApi from '@/api/storytask'
import * as StoryPromptApi from '@/api/prompt'
import * as FileApi from '@/api/file'
import * as StoryVarsApi from '@/api/storyvars'
import { mapState, mapMutations} from 'vuex'
export default {
  name: 'Article',
  components: {
    HeadNavWriter,BookBreakdownDetailDialogFen,BookBreakdownDetailDialogHe,LlmSkeleton,WriteTips
  },
  data() {
    return {
      treeData: [],
      tableData: [],
      storyVars: [],
      storyTask: {},
      aiMasterFlag: true,
      editFlag: false,
      titleInputValue: '',
      nameInputValue: '',
      storyChapterOutline: '',
      storyChapterOutlineRequired: '',
      queryForm: {
        chapterId: [],
        chapterTitle: '',
        storyPromptId: '',
        fromType: 'chaishu'
      },
      breakdownPrompts: [],
      promptDesc: '',
      storyPrompt: {},
      currentNode: {},
      currentParentNode: {},
      currentChapter: {},
      currentVolume: {},
      isLoading: false,
      showSkeleton: true,
      hideFlagML: false,
      hideFlagZW: false,
      selectedWordCount: 0,
      selectedSingleWordCount: 0,
      selectedChapterCount: 0,
      storyContent: '',
      storyContentObj: {},
      paginations: {
        current: 1, // 当前页
        total: 0, // 总行数
        size: 20, // 每页行数
        layout: 'total, prev, pager, next' // 翻页属性
      }
    }
  },
  computed: {
  	...mapState(['user', 'story', 'showBreakdownDetailDialogFlagV2', 'showBreakdownDetailDialogFlagHe','uploadFile', 'enums']),
    isMobile() {
      return window.innerWidth <= 768
    }
  },
  mounted() {
    localStorage.removeItem('currentPath')
    if(this.isMobile){
      this.hideFlagZW = true
    }
    this.handlePageChapter(0)
    this.handlePagePrompt()
    this.handlePageTask()
    console.log('buttonsize', this.$root.buttonSize)

    //refrush
    this.intervalId = setInterval(() => {
      let items = this.tableData.filter((item) => {return item.breakdownState == "SUBMITTED" || item.breakdownState == "RUNNING"})
      if(items.length > 0){
        this.shuaxin()
      }
    }, 5000);
  },
  methods: {
    ...mapMutations(['setStory', 'setShowBreakdownDetailDialogFlagV2', 'setShowBreakdownDetailDialogFlagHe']),
    handleChangePrompt(option){
      console.log('option', option);
      this.promptDesc = ''
      this.breakdownPrompts.forEach((item, index) => {
        if(item.id == option){
          this.promptDesc = this.replaceLineBreaks(item.remark)
          this.storyPrompt = item
          return false
        }
      })
      this.handlePageStoryVars()
    },
    replaceLineBreaks(str) {
      return str?str.replace(/\\n/g, '\n').replace(/\n/g, '<br>'):"";
    },
    replaceLineBeginBreaks(text) {
      // let text = "\n\nThis is a text with leading blank lines.\n";
      text = text.replace(/^\s*\n/gm, ""); // 删除开头的空行
      // console.log(text);
      return text;
    },
    focusOutline() {
      let input = this.$refs.outlinebox
      input.focus();
    },
    handlePageChapter(flag){
      let uploadFileId = this.uploadFile.id
      if(!uploadFileId) {
        return false;
      }
      ChapterApi.page({uploadFileId, size: 5000}).then(res => {
        let result = res.data.data;
        if(res.data.code == 0){
          if(result.total > 0){
            this.chapters = result.records;
            this.treeData = this.chapters.filter(item => {
              return item.parentId == 0
            })
            this.treeData.forEach((item, index) => {
              if (!item.children) {
                this.$set(item, 'children', []);
              }
              this.$set(item, 'children', this.chapters.filter(itemIn => {
                return itemIn.parentId == item.id
              }));
            })
          }else{
            console.log('无章节数据')
          }
        }
      })
    },
    handlePageTask(){
      let uploadFileId = this.uploadFile.id
      if(!uploadFileId) {
        return false;
      }
      StoryTaskApi.page({uploadFileId, size: 20}).then(res => {
        let result = res.data.data;
        this.tableData = result.records;
        this.paginations.total = Number(result.total)
        this.paginations.current = Number(result.current)
        this.paginations.size = Number(result.size)
      })
    },
    handlePagePrompt(){
      StoryPromptApi.page({promptGroup: 'BREAKDOWN', enableFlag: true, size: 20}).then(res => {
        let result = res.data.data;
        this.breakdownPrompts = result.records;
      })
    },
    handlePageStoryVars(){
      if(!this.queryForm.storyPromptId){
        this.storyVars = []
        return false;
      }
      StoryVarsApi.page({storyPromptId: this.queryForm.storyPromptId, size: 100}).then(res => {
        let result = res.data.data;
        this.storyVars = result.records;
      })
    },
    handleCreateChapter(flag) {
      if(!this.story.id){
        this.$message.error('故事ID丢失，请重新进入。');
        return false;
      }
      let params = {}
      params.articleId = this.story.id
      console.log('flag='+ flag, this.currentNode, this.currentVolume)
      if(flag == 0){
        // 章
        // 当前章
        let childNodes = this.currentVolume.childNodes || []
        let lastChild = childNodes.length > 0 ? childNodes[childNodes.length-1] : null
        let chapterNumber = lastChild ? this.extractFirstNumber(lastChild["data"]["title"]) + 1 : 1
        console.log(childNodes, lastChild, chapterNumber)
        let data = {}
        if(this.currentNode.level == 2){
          params.parentId = this.currentParentNode.data.id
          // chapterNumber = this.currentParentNode.childNodes.length
          data = this.currentParentNode.data;
        } else {
          params.parentId = this.currentNode.data.id
          // chapterNumber = this.currentNode.childNodes.length
          data = this.currentNode.data;
        }
        if(!params.parentId){
          this.$message.error('请选择章节');
          return false;
        }
        console.log('create chapter data', data);

        params.chapterNumber = chapterNumber
        params.title = '第'+params.chapterNumber+'章'
        ChapterApi.create(params).then(res => {
          console.log('create chapter', res);
          const newChild = res.data.data;
          if (!data.children) {
            this.$set(data, 'children', []);
          }
          data.children.push(newChild);
        });

      }else if(flag == 1){
        console.log('第几卷',this.currentNode, this.currentParentNode);
        // 卷
        // 追加到最后，名字：第几卷
        params.parentId = 0
        let chapterNumber = 0
        let data = {}
        if(this.currentNode.level == 2){
          chapterNumber = this.currentParentNode.parent.data.length
          data = this.currentParentNode.parent.data;
        } else {
          chapterNumber = this.currentNode.parent.childNodes.length
          data = this.currentNode.parent.data;
        }
        params.chapterNumber = chapterNumber+1
        params.title = '第'+(chapterNumber+1)+'卷'
        ChapterApi.create(params).then(res => {
          console.log('create chapter', res);
          const newChild = res.data.data;
          if (!data.children) {
            this.$set(data, 'children', []);
          }
          data.push(newChild);
        });
      }
    },
    handleRename(node) {
      console.log('handleRename', node);
      this.editFlag = true;
      this.nameInputValue = node.data.title
      console.log('this.editFlag', this.editFlag, this.nameInputValue);

    },
    handleRenameOk(node) {
      console.log('handleRenameOk', node);
      if(!this.nameInputValue || !this.nameInputValue.trim()){
        return false;
      }
      ChapterApi.update({id:node.data.id, title: this.nameInputValue}).then(res => {
        node.data.title = this.nameInputValue
        this.editFlag = false;
      });
    },
    handleRemove(node) {
      this.$confirm('', '确定删除吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(() => {
        ChapterApi.remove(node.data.id).then(res => {
          this.handlePageChapter(1)
        });
      }).catch((e) => {console.log('error', e)});
    },
    getTopLevelFirstFiveNodesAndCheck() {
      console.log('treeData', this.treeData);
      // 获取前5个顶层节点的id
      const topLevelNodesIds = this.getTopLevelFirstFiveNodes(this.treeData).map(node => node.id);
      // 设置这5个节点为选中状态
      this.$refs.tree.setCheckedKeys(topLevelNodesIds);
    },
    getTopLevelFirstFiveNodes(nodes) {
      let count = 0; // 用于跟踪已找到的非父节点数量
      let topLevelNodes = []; // 用于存储找到的顶层节点

      // 递归函数来遍历节点
      function traverse(nodes) {
        for (let i = 0; i < nodes.length; i++) {
          // 如果已经找到5个节点，停止递归
          if (count >= 5) return;
          // 检查当前节点是否为顶层节点（即没有父节点）
          if (!nodes[i].children ) {
            topLevelNodes.push(nodes[i]);
            count++;
          }
          // 如果当前节点有子节点，继续递归子节点
          if (nodes[i].children && nodes[i].children.length > 0) {
            traverse(nodes[i].children);
          }
        }
      }
      // 开始递归遍历
      traverse(nodes);
      return topLevelNodes;
    },
    getNextFiveNodesAfterKey() {
      let selectedKey = this.getLastCheckedKey()
      console.log('selectedKey', selectedKey);
      if(!selectedKey) return false
      let currentIndex = -1;
      let nextNodes = [];
      let found = false;

      // 深度优先搜索找到选中的节点及其后5个节点
      function traverse(nodes, index) {
        if (index === undefined) {
          index = 0;
        }
        for (let i = index; i < nodes.length; i++) {
          if (nodes[i].id === selectedKey) {
            currentIndex = i;
            found = true;
            break;
          }
        }
        if (found) {
          // 找到选中的节点后，继续遍历其后的节点
          for (let j = currentIndex + 1; j < nodes.length && j < currentIndex + 6; j++) {
            nextNodes.push(nodes[j]);
          }
        } else {
          // 如果当前层级没有找到，递归子节点
          nodes.forEach(node => {
            if (node.children && node.children.length > 0) {
              traverse(node.children, 0);
            }
          });
        }
      }

      traverse(this.treeData);

      const topLevelNodesIds = nextNodes.map(node => node.id);
      // 设置这5个节点为选中状态
      const checkedKeys = this.$refs.tree.getCheckedKeys();
      this.$refs.tree.setCheckedKeys([...checkedKeys, ...topLevelNodesIds]);

      return nextNodes;
    },
    selectAll() {
      // 获取所有的节点的key
      const allKeys = this.getAllKeys(this.treeData);
      // 通过setCheckedKeys方法设置所有节点为选中状态
      this.$refs.tree.setCheckedKeys(allKeys);
    },
    getAllKeys(nodes) {
      let keys = [];
      if (nodes) {
        nodes.forEach(node => {
          keys.push(node.id); // 假设每个节点都有唯一的id属性
          if (node.children) {
            keys = keys.concat(this.getAllKeys(node.children));
          }
        });
      }
      return keys;
    },
    clearAll() {
      // 清空所有选中的节点
      this.$refs.tree.setCheckedKeys([]);
    },
    getLastCheckedKey() {
      // 获取所有选中节点的keys
      const checkedKeys = this.$refs.tree.getCheckedKeys();
      // 检查是否有选中的节点
      if (checkedKeys.length > 0) {
        // 获取最后一个选中节点的key
        const lastCheckedKey = checkedKeys[checkedKeys.length - 1];
        return lastCheckedKey;
      } else {
        // 如果没有选中的节点，返回null或者合适的默认值
        return null;
      }
    },
    handleCheckChange(data, checked, indeterminate) {
      console.log('data', data, 'checked', checked);
      if (data.children) {
        // 如果父节点被选中，选中所有子节点
        if(checked){
           this.selectAll();
        }else{
          this.clearAll();
        }
      }else{
        if(!checked){
          // 所有子节点没有选中，父节点也取消选中
          let childs = this.getCheckedKeysNoParent()
          if(childs.length == 0){
            this.clearAll();
          }
        }
      }

      let checkedNodes = this.$refs.tree.getCheckedNodes();
      let checkedKeys = this.$refs.tree.getCheckedKeys();
      // 处理选中节点数据
      // console.log('checkedNodes', checkedNodes, 'checkedKeys', checkedKeys);

      // 设置 字数、章节数 selectedWordCount、selectedChapterCount
      let wordNumber = 0
      let chapterNumber = 0
      let singleWordNumber = 0
      checkedNodes.forEach((node, index) => {
        if(!node.children){
          wordNumber += node.wordNumber
          chapterNumber += 1
          singleWordNumber = singleWordNumber<node.wordNumber?node.wordNumber:singleWordNumber
        }
      });
      this.selectedChapterCount = chapterNumber
      this.selectedWordCount = wordNumber
      this.selectedSingleWordCount = singleWordNumber
    },
    getCheckedKeysNoParent() {
      let checkedNodes = this.$refs.tree.getCheckedNodes();
      this.queryForm.chapterId = []
      this.queryForm.chapterTitle = []
      let chapterTitles = []
      let children = []
      checkedNodes.forEach((node, index) => {
        if(!node.children){
          this.queryForm.chapterId.push(node.id)
          chapterTitles.push(node.title)
          this.queryForm.chapterTitle = chapterTitles.join(',')
          children.push(node)
        }
      });
      return children
    },
    historyTask(){
      this.hideFlagML = true
      this.hideFlagZW = false
    },
    checkedChapter(){
      this.hideFlagML = false
      this.hideFlagZW = true
    },
    handleNodeClick(item, node, tree) {
      this.currentNode = node
      this.currentParentNode = node.parent;
      this.currentVolume = node.level == 1 ? node : node.parent
      this.currentChapter = node.level == 2 ? node : {}
      console.log('nodeClick', item, node, node.parent, tree);

      let checkedKeys = this.$refs.tree.getCheckedKeys();
      checkedKeys.push(item.id)
      this.$refs.tree.setCheckedKeys(checkedKeys);
    },
    remove(node, data) {
      const parent = node.parent;
      const children = parent.data.children || parent.data;
      const index = children.findIndex(d => d.id === data.id);
      children.splice(index, 1);
    },
    handleEnum() {
      EnumApi.list().then(res => {
        this.enums = res.data.data;
      })
    },
    handleChakan(item) {// result
      this.storyTask = item
      console.log('breakdownCategory', item.breakdownCategory);
      if(item.breakdownCategory == 'MERGE'){
        this.setShowBreakdownDetailDialogFlagHe(true)
      }else if(item.breakdownCategory == 'CHAPTER'){
        this.setShowBreakdownDetailDialogFlagV2(true)
      }
    },
    handleRetry(item) {// result
      StoryTaskApi.update({id: item.id, retryFlag: true, breakdownState: 'SUBMITTED'}).then(res => {
        this.handlePageTask();
      });
    },
    handleZhongzhi(item) {// result
      StoryTaskApi.update({id: item.id, breakdownState: 'TERMINAL'}).then(res => {
        this.handlePageTask();
      });
    },
    handleRemoveTask(item) {
      this.$confirm('', '确定删除吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass: 'custom-confirm',
        center: true
      }).then(() => {
        StoryTaskApi.remove(item.id).then(res => {
          this.handlePageTask();
        });
      }).catch((e) => {console.log('error', e)});
    },
    goHelp(){
      window.open('https://qcnx120ai68z.feishu.cn/wiki/D1Qkw0Kqoic66MkvL0NcAw8gnqg#share-SuwwdO6ZhoJT2qxcGoGcir7vnxu','bank')
    },
    createTask() {
      if (this.selectedChapterCount == 0) {
          this.$message.error('请选择章节');
          return false;
      }
      if (this.queryForm.storyPromptId == '') {
          this.$message.error('请选择拆书类型');
          return false;
      }
      this.isLoading = true
      this.getCheckedKeysNoParent()
      console.log('createTask', this.queryForm,
       'selectedSingleWordCount='+this.selectedSingleWordCount,
        'selectedWordCount='+this.selectedWordCount,
        'storyVars'+this.storyVars);

      // 动态参数校验 requiredFlag=true
      for (let index = 0; index < this.storyVars.length; index++) {
        let element = this.storyVars[index];
        let placeholder = '请输入 '
        if(element.fieldType == 'SELECT'){
          placeholder = '请选择 '
        }
        if(element.requiredFlag){
          if(!element.value){
            this.$message.error(placeholder + element.fieldLabel);
            this.isLoading = false
            return false;
          }
        }
      }
      // console.log('error')
      // return false;

      let tishi = ''
      let showTishi = false
      if(this.storyPrompt.breakdownCategory == 'CHAPTER' && this.selectedSingleWordCount>30000){
        // 分章拆 检查单章正文字数
        showTishi = true
        tishi = "您所选章节中有字数超过3万字的章节，由于 AI 单次处理的字数有限，您可以本地拆分章节后重新上传作品，如继续提交，则只截取章节前3万字进行拆书，请您知晓！"
      }else if(this.storyPrompt.breakdownCategory == 'MERGE' && this.selectedWordCount>30000){
        // 合并拆 检查全部正文字数
        showTishi = true
        tishi = "您所选章节总字数超过3万字，由于AI当前可处理字数有限，请您分多次进行拆书，如您继续提交，则只会截取前3万字的内容给AI拆书，请您知晓！"
      }

      if(showTishi){
        this.$confirm(tishi,'提示', {
           confirmButtonText: '继续提交',
           cancelButtonText: '取消',
           customClass: 'custom-confirm',
           center: false
         }).then(() => {
           this.doCreateTask()
         }).catch((e) => {
           this.isLoading = false
           console.log('error', e)
         });
      }else{
        this.doCreateTask()
      }
    },
    doCreateTask(){
      let params = Object.assign({}, this.queryForm)
      params.uploadFileId = this.uploadFile.id
      params.storyVars = this.storyVars
      StoryTaskApi.create(params).then(res => {
        this.handlePageTask()
        this.isLoading = false
      });
    },
    shuaxin() {
      console.log('shuaxin');
      this.handlePageTask()
    },
    nodeHover(node){
      console.log(node)
    },
    extractFirstNumber(inputString) {
        // 使用正则表达式匹配第一个数字
        const match = inputString.match(/\d+/);

        // 如果匹配到数字，则返回该数字，否则返回 null
        return match ? Number(match[0]) : 0;
    },
    handleCurrentChange(page) {
      // 切换页码
      this.paginations.current = page
      this.handlePageTask()
    },
    handleSizeChange(size) {
      // 切换size
      this.paginations.size = size
      this.size = size
      this.handlePageTask()
    }
  },
  beforeDestroy() {
    this.setStory({id: ''})
    // 清除定时器，停止周期性执行
    if (this.intervalId) {
      // 清除定时器，停止周期性执行
      clearInterval(this.intervalId);
    }
  }
}
</script>
<style>
.el-button {
  border-radius: 12px !important;
}
</style>
<style scoped lang="scss">
$lightBgColor: #E5E7FC;
$lightBgColor2: #CDD1FF;
$mainTextColor: #0052D9;
.writer-box {
  &-content {
    background: #FAFAFD;
    padding: 1rem;
    &-work {
        height: calc( 100% - .5rem );
      &-left {
        background: #FFFFFF;
        box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.05);
        border-radius: 20px;
        width: 100%;
        display: flex;
        padding: 1rem;
        margin-right: 1rem;
      }
      &-outline {
        height: 100%;
        // width: 15rem;
        overflow-y: scroll; /* 启用垂直滚动条 */
        overflow-x: hidden; /* 隐藏水平滚动条 */
        &-title {
          font-weight: 600;
          font-size: 1rem;
          color: $mainTextColor;
          padding: .5rem 0;
          &-disabled {
            color: rgba(36,36,36,0.3);
          }
        }
        &-time {
          font-weight: 400;
          font-size: .8rem;
          color: rgba(0,0,0,0.6);
          padding: .5rem 0;
          &-disabled {
            color: rgba(36,36,36,0.3);
          }
        }
      }
      &-editor {
        // width: calc( 100% - 15rem );
        width: 100%;
        height: calc( 100% - 4rem );
        border-radius: 20px ;
      }
      &-current-outline {
        // width: 20rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        &-top {
          background: #F2F3FF;
          border-radius: 20px;
          height: 100%;
          margin-bottom: 1rem;
        }
      }
    }
    &-title {
    }
  }
}
/deep/ .el-dropdown-menu.el-popper {
  left: 2rem;
}
/deep/ .writer-box-content {
    max-width: 1400px !important;
    height: calc(100% - 6rem);
}

.max-width-column .cell {
  max-width: 15rem; /* 设置最大宽度 */
  white-space: nowrap; /* 防止文本换行 */
  overflow: hidden; /* 隐藏超出部分 */
  text-overflow: ellipsis; /* 显示省略号 */
}
.outline-list-box {
  height: calc( 100% - 16rem);
  overflow-y: scroll;
  background: #FFFFFF;
  padding: 1rem;
}
.outline-list-box div:first-child {
  margin-top: 0 !important;
}
/deep/ .el-textarea__inner {
  resize: none; /* 禁止调整大小 */
}
.custom-select, .custom-zidingyi {
    /deep/ .el-input__inner {
      border-radius: 12px;
      height: 28px;
      line-height: 28px;

      background: transparent;
      border: 1px solid #0052D9;;
    }
    /deep/ .el-input__suffix {
      height: 28px;
      line-height: 28px;
      font-size: 12px;
      display: flex;
      align-items: center;
    }
}
/deep/ .el-select-dropdown {
  border-radius: 12px;
}
/deep/ .el-select-dropdown__item {
  border-radius: 12px;
}

/deep/ .el-icon-files:before, .el-icon-document:before  {
    margin-right: .25rem;
}
.custom-button.default:hover {
    background-color: #F2F3FF;
    // background-color: #000000;
    // color: white;
}
.custtom-button1.default:hover {
    background-color: #F2F3FF;
}

.mly-switch {
  /deep/ .el-switch__label.is-active{
    color: rgba(0,0,0,0.5);
    white-space: nowrap;
  }
  /deep/ .el-switch__label {
    white-space: nowrap;
    color: rgba(0,0,0,0.5);
    span {
      font-size: 12px;
    }
  }
  /deep/ .el-switch__core {
    width: 30px !important;
    height: 14px !important;
    border-radius: 10px !important;
  }
  /deep/ .el-switch__core:after {
    top: 0 !important;
    left: 17px !important;
    width: 12px !important;
    height: 12px !important;
    // margin-left: -16px;
  }
  /deep/ .el-switch.is-checked .el-switch__core {
    width: 30px !important;
    height: 14px !important;
    left: 0 !important;
    border-radius: 10px !important;
  }
}
.mly-fuzhuwenzi {
  font-weight: 400;
  font-size: 12px;
  color: rgba(0,0,0,0.4);
  margin: .5rem 0;
}
.icon-bg-outer {
  background: #F0F1FF;
  border-radius: 10px;
  margin-bottom: 8px;
  padding: 6px;
}
.icon-bg {
  background-image: url('../../assets/icon-tips-lite.png');
  background-repeat: no-repeat;
  background-position: 0px 2px;
  background-size: 12px 12px;
}
/* 取消行悬停效果 */
/deep/ .el-table--enable-row-hover {
    background-color: transparent !important;
}

/* 取消行过渡效果 */
/deep/ .el-table--enable-row-transition {
    transition: none !important;
}
/deep/ .el-table th.el-table__cell.is-leaf, /deep/ .el-table td.el-table__cell {
  border-bottom: none;
}
.table-container {
  margin-bottom: 1rem;
  /deep/ .el-table__body {
    border-spacing: 0 10px !important;
    border-radius: 20px;
    width: auto !important;
  }
  /deep/ .el-table__body-wrapper.is-scrolling-left{
    width: auto !important;
  }
  /deep/ .el-table__body-wrapper .el-table__empty-block {
    width: auto !important;
  }
}

::v-deep .el-table td:first-child {
  border-radius: 20px 0 0 20px;
  padding-left: 2rem;
}
::v-deep .el-table td:last-child {
  border-radius: 0 20px 20px 0;
}

::v-deep td.el-table__cell {
  color: rgba(0,0,0,0.8);
  background-color: transparent !important;
}
::v-deep .el-table .el-table__cell:first-child {
  font-weight: bold;
  padding-left: 2rem;
}
::v-deep .el-table__row:hover{
  background-color: #E5E7FC !important;
}
::v-deep .el-table__row {
  font-weight: 400;
  font-size: 14px;
  color: rgba(0,0,0,0.8);
}
/deep/ .el-table{
  height: 100% !important;
}
/deep/ .el-table__body-wrapper{
  height: 100% !important;
}
/deep/ .el-table__empty-text {
 height: 100%; /* 或者设置为具体的数值，如500px */
 line-height: 20px !important;
}
/deep/ .el-table__empty-text {
 height: 100%; /* 或者设置为具体的数值，如500px */
 line-height: 20px !important;
}
.mly-data-empty {
  height: 100%; /* 或者设置为具体的数值，如500px */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.image-switcher {
  display: inline-block;
  width: 14px;
  height: 14px;
  background-size: cover;
  transition: background-image 0.3s; /* 平滑过渡效果 */
  &-sousuo {
    background-image: url('../../assets/svg/icon-sousuo.svg'); /* 默认状态下的图片 */
  }
  &-sousuo:hover {
    background-image: url('../../assets/svg/icon-sousuo-1.svg'); /* 默认状态下的图片 */
  }
  &-sousuo:active {
    background-image: url('../../assets/svg/icon-sousuo.svg'); /* 默认状态下的图片 */
  }

  &-shuaxin {
    background-image: url('../../assets/icon-shuaxin.png'); /* 默认状态下的图片 */
  }
  &-shuaxin:hover {
    background-image: url('../../assets/icon-shuaxin-1.png'); /* 激活状态下的图片 */
  }
  &-shuaxin:active {
    background-image: url('../../assets/icon-shuaxin.png'); /* 默认状态下的图片 */
  }

  &-delete {
    background-image: url('../../assets/icon-delete.png'); /* 默认状态下的图片 */
  }
  &-delete:hover {
    background-image: url('../../assets/icon-delete-1.png'); /* 默认状态下的图片 */
  }
  &-delete:active {
    background-image: url('../../assets/icon-delete.png'); /* 默认状态下的图片 */
  }

  &-zhongzhi {
    background-image: url('../../assets/svg/icon-zhongzhi.svg'); /* 默认状态下的图片 */
  }
  &-zhongzhi:hover {
    background-image: url('../../assets/svg/icon-zhongzhi-1.svg');
    // background-image: url('../../assets/icon-zhongzhi-1.png'); /* 默认状态下的图片 */
  }
  &-zhongzhi:active {
    background-image: url('../../assets/svg/icon-zhongzhi.svg'); /* 默认状态下的图片 */
  }
}
@media screen and (max-width: 768px) {

  .writer-box-content-work {

  }
}
</style>
