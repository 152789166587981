<template>
<div :style="{'width': drawerWidth}">
    <el-upload
      class="ly-uploader"
      action="#"
      :before-upload="handleBeforeUpload"
      :on-preview="handlePreview"
      :on-success="handlePreview"
      :on-remove="handleRemove"
      :file-list="fileList"
      :on-progress="handleProgress"
      :on-change="handleChange"
      :on-exceed="handleExceed"
      list-type="text"
      :show-file-list="false"
      accept=".txt,.doc,.docx"
      :limit="limit"
      :http-request="httpRequest">
      <div class="el-upload__text">
        <div style="display: flex;">
          <el-button class="el-button-dashed mly-border-radius-12" style="padding: 10px 55px;color: #0052D9;font-size: 12px;font-weight: 400;" icon="el-icon-upload2" :loading="loadingFlag" plain>选择文件上传</el-button>
        </div>
      </div>
      <div style="line-height: 1rem !important;color: rgba(0, 0, 0, .4);margin-bottom: .25rem;" class="el-upload__tip" slot="tip">
        仅支持<span style="color: #125FDC;padding: 0 .25rem;">txt, doc, docx</span>文件，文件不超过<span style="color: #125FDC;padding: 0 .25rem;">300MB</span>

        <div style="height: 100px;" v-show="(!loadingFlag)&&fileList && fileList.length == 0"></div>
        <el-skeleton v-show="loadingFlag" :rows="3" style="margin-top: 1rem;" animated/>

        <!-- error -->
        <div v-if="errorFlag" class="icon-bg-error-outer">
            <div class="icon-bg-error d-flex flex-between flex-y-center">
                <span>{{fileList && fileList.length > 0 ? fileList[0].name : '文件不超过'}}</span>
                <i class="el-icon-error" style="color: red;font-size: 20px;"></i>
            </div>
        </div>
        <!-- success -->
        <div v-if="successFlag" class="icon-bg-success-outer">
            <div class="icon-bg-success d-flex flex-between flex-y-center">
                <span>{{fileList && fileList.length > 0 ? fileList[0].name : '文件不超过'}}</span>
                <i class="el-icon-success" style="color: green;font-size: 20px;"></i>
            </div>
        </div>
        <!-- file check -->
        <div v-if="successFlag" class="file-check">
          <div class="d-flex">
            <div class="file-check-circle">1</div>
            <div style="padding-left: 6px;color: #0052D9;font-weight: 600;font-size: 14px;
    display: flex;
    align-items: center;">检查读取文件内容是否正确？</div>
          </div>
          <div class="d-flex" style="border-radius: 6px;">
            <div class="file-check-bar">
              <div class="file-check-bar-inner"></div>
            </div>
            <div style="padding-left: 6px;">
              <div style="font-size: 10px;color: rgba(0,0,0,0.4);padding-top: 10px; padding-bottom: 6px;">如内容乱码可点击<a href="https://qcnx120ai68z.feishu.cn/wiki/D1Qkw0Kqoic66MkvL0NcAw8gnqg#share-ZwSrd7wwIomZ5dxzTgncH4Nsnyb" target="_blank" style="color: #125FDC;">查看帮助</a></div>
              <div v-html="replaceLineBreaks(content)"
                style="border-radius: 12px; background: #FFFFFF;
                text-align: justify;color: rgba(0,0,0,0.8);
                box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.03);padding: 1rem;margin-bottom: 10px;">

              </div>
            </div>
          </div>
          <div class="d-flex">
            <div class="file-check-circle">2</div>
            <div style="padding-left: 6px;width: 100%;">
              <div style="color: #0052D9;font-weight: 600;font-size: 14px;height: 20px;
    display: flex;
    align-items: center;">检查读取章节数量是否正确？</div>
              <div style="font-size: 10px;color: rgba(0,0,0,0.4);padding-top: 10px; padding-bottom: 6px;">如果章数显示为0，则为异常，请
                <a style="color: #125FDC;">
                  <el-tooltip placement="top" style="display: inline-block;">
                    <div slot="content" style="line-height: 20px;">
                      请检查章节数是否正确，如有问题，请修改章节标题重新上传，章节标题要符合以下规则：<br>
                      *1. 章节标题要单独一行 <br>
                      *2. 章节标题要符合以下任一格式： <br>
                      &nbsp;&nbsp;数字型章节序号：1 或 01 或 001 <br>
                      &nbsp;&nbsp;数字型章节序号+标题：1 xxx 或 01 xxxx <br>
                      &nbsp;&nbsp;混合型章节序号：第X章，例如第100章 或 第一百章 <br>
                      &nbsp;&nbsp;混合型章节序号+标题： 第X章 xxxx <br>
                      &nbsp;&nbsp;英文+数字序号：part 1 <br>
                      &nbsp;&nbsp;英文+数字序号：Chapter 1
                    </div>
                    <div style="">
                      检查文章格式
                    </div>
                  </el-tooltip>

                </a>
              </div>
              <div style="color: rgba(0,0,0,0.8);border-radius: 12px; background: #FFFFFF;
                text-align: justify;
                box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.03);padding: 1rem;">上传文件的章节数为：<a style="color: #125FDC; cursor: default;">{{zhanggangNumber}}</a> 章</div>

            </div>
          </div>
        </div>
      </div>

    </el-upload>
</div>
</template>

<script>
import { mapState, mapMutations} from 'vuex'
import  * as AliossApi from '@/api/alioss'
import * as FileApi from '@/api/file'
const OSS = require('ali-oss');
export default {
  name: 'RichEditor',
  props: {
    picSize: {
      type: String,
      default: '160x160'
    },
    url: {
      type: String,
      default: ''
    },
    limit: {
      type: Number,
      default: 1
    }
  },
  mounted() {
    if(this.url){
      this.url.split(',').forEach((item,inex)=>{
        this.fileList.push({name: '', url: item})
      })
    }
  },
  computed: {
    ...mapState(['successFlag']),
    drawerWidth() {
      return window.innerWidth <= 768 ? '100%' : '100%';
    },
  },
  data() {
    return {
        fileList: [],
        loadingFlag: false,
        errorFlag: false,
        content: '',
        zhanggangNumber: 0,
        progressPercent: 0
    }
  },
  methods: {
    ...mapMutations(['setSuccessFlag']),
    handleProgress(event, file, fileList) {
      console.log('handleProgress', event, file);
      this.progressPercent = Math.floor(event.percent);
    },
    handleChange(file, fileList){
      console.log('handleChange', file, fileList);
    },
    replaceLineBreaks(str) {
      return str?str.replace(/\\n/g, '\n').replace(/\n/g, '<br>'):"";
    },
    async handleExceed(files, fileList) {
      // this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
      let p1 = Object.assign({}, files)
      let p2 = Object.assign({}, fileList)
      console.log('handleExceed', p1, p2);
      if(p1[0].name == p2[0].name && p1[0].size == p2[0].size){
        return
      }
      if (fileList.length >= 1) {
        fileList.splice(0, 1, files[0]);
      }
      this.fileList = fileList;
      this.httpRequest({file: this.fileList[0]})
    },
    readAsTextSync(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsText(file);
      });
    },

    // 使用 async/await 语法来“同步”读取文件
    async readFileAndCountChineseCharacters(file) {
      try {
        const content = await this.readAsTextSync(file);
        const count = content.length;
        console.log('中文字数：', count);
        return count
      } catch (error) {
        console.error('读取文件时出错：', error);
      }
    },
    async httpRequest(item) {
      this.loadingFlag = true
      console.log('httpRequest', item);
      var file = item.file

      // 文件大小
      const maxSize = 300 * 1024 * 1024; // 10m
      // const maxSize = 10 * 1024; // 10k
      if (file.size > maxSize) {
        this.fileList = []
        this.$message.error('文件大小不能超过 300 MB');
        return false; // 阻止上传
      }
      // 字数
      let wordCount = 0
      // if(file){
      //   wordCount = await this.readFileAndCountChineseCharacters(file);
      // }
      // console.log('字数2：', wordCount);
      // if (wordCount > 30000) {
      //   this.fileList = []
      //   this.$message.error('字数不超过3万');
      //   return false; // 阻止上传
      // }

      let fileType = file.type.toLowerCase()
      let ext=''
      if(fileType.indexOf('text') >= 0){
        ext = '.txt';
      }else if(fileType === 'application/msword' || fileType === 'application/wps-writer'){
        ext = '.doc';
      }else if(fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'){
        ext = '.docx';
      } else {
        console.log('This is not a .doc or .docx file.');
      }

      let storeAs = process.env.VUE_APP_CURRENTMODE + '/' + Number(new Date().getTime()/(3600*24*7*1000)).toFixed(0) + '/' + this.guid().replace(/-/g,'') + ext
      console.log(fileType,storeAs);

      let res = await AliossApi.ststoken()
      const result = res.data.data
      const client = new OSS({
        accessKeyId: result.accessKeyId,
        accessKeySecret: result.accessKeySecret,
        stsToken: result.stsToken,
        endpoint: result.endpoint,
        bucket: result.bucket
      })
      client.multipartUpload(storeAs, file, {
        progress: (percentage) => {
          console.log(`上传进度：${percentage * 100}% file: `, file);
        }
      }).then(result => {
        console.log('multipartUpload result', result);
        let picUrl = result.res.requestUrls[0];
        picUrl = picUrl.split('?')[0];
        this.fileList = [{name: file.name, url: picUrl}]
        console.log('picUrl', picUrl, this.fileList);

        let url =  picUrl.split("?")[0];
        if (url != null && url.length > 0) {
          // 上传成功
          console.log('上传结束：', this.$parent.$parent.$parent.$parent);
          if(this.$parent.$parent.$parent.$parent.getFile){
            this.$parent.$parent.$parent.$parent.getFile();
          }
          // 检测格式
          let params = {
            fromType: 'check_file',
            url: url
          }
          FileApi.create(params).then(res => {
             console.log('create file', res, 'loadingFlag', this.loadingFlag);
             let result = res.data
             this.loadingFlag = false
             if(result.code === -1){
               // this.$message.error("上传失败！网络出现问题，请重新上传文件！")
               this.errorFlag = true
               this.setSuccessFlag(false)
             }else{
               this.zhanggangNumber = result.data.zhanggangNumber
               this.setSuccessFlag(true)
               this.errorFlag = false
             }
             this.content = result.data.content

          });
        } else {
          this.$message.error("上传失败！网络出现问题，请重新上传文件！")
          this.errorFlag = true
          this.setSuccessFlag(false)
          this.loadingFlag = false
        }
      }).catch(function(err) {
        console.log('upload', err);
      })
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      let temp = []
      this.fileList = temp.concat(fileList)
      if(this.$parent.$parent.$parent.$parent.getFile){
        this.$parent.$parent.$parent.$parent.getFile();
      }
    },
    handlePreview() {
      console.log('handlePreview', 1);
      // picUrl
    },
    getFile() {
      if(this.fileList.length > 0) {
        return this.fileList[0]
      } else {
        return {};
      }
    },
    async handleBeforeUpload(file) {

      return true; // 允许上传
    },
    guid() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,
       (c) => {
          var r = (Math.random() * 16) | 0
          var v = c == 'x' ? r : (r & 0x3) | 0x8
          return v.toString(16)
        })
    },
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-upload {
  width: 100% !important;
  text-align: left;
}
/deep/ .el-upload-dragger {
  width: 100% !important;
}

.icon-bg-error-outer {
  margin-bottom: 10px;
  margin-top: 6px;
  background: #FFF4F3;
  border-radius: 12px;
}
.icon-bg-error {
  background-image: url('../../assets/icon-upload-bg.png');
  background-repeat: no-repeat;
    background-position: 0px 0px;
    background-size: 40px 40px;
    border-radius: 6px;
    padding-left: 40px;
    padding-right: 12px;
    line-height: 48px;
}
.icon-bg-success-outer {
  margin-bottom: 10px;
  margin-top: 6px;
  background: rgba(191,241,210,0.22);
  border-radius: 12px;
}
.icon-bg-success {
  background-image: url('../../assets/icon-upload-bg-success.png');
  background-repeat: no-repeat;
    background-position: 0px 12px;
    background-size: 40px 40px;
    border-radius: 6px;
    padding-left: 40px;
    padding-right: 12px;
    line-height: 48px;
}
.file-check {
   padding: 1rem;
   background: #FAFAFD;
   border-radius: 12px;
   &-circle {
     width: 20px;
     height: 20px;
     display: flex;
     align-items: center;
     justify-content: center;
     background: #0052D9;
     color: #FFFFFF;
     border-radius: 50%;
   }
   &-bar {
     width: 20px;
     padding: 10px 10px;
     &-inner{
       width: 1px;
       background: #0052D9;
       height: 100%;
     }
   }
}
/* 定义一个类选择器，可以给需要虚线边框的按钮添加这个类 */
.el-button-dashed {
  border-style: dashed; /* 设置边框样式为虚线 */
  border-width: 1px; /* 设置边框宽度 */
  border-color: #dcdfe6; /* 设置边框颜色，可以根据需要调整 */
}

/* 如果你想要改变hover状态下的边框样式，可以添加以下样式 */
.el-button-dashed:hover {
  border-color: #409eff; /* 鼠标悬停时的边框颜色 */
}
</style>
