<template>
  <div class="writer-box writer-bg">
    <HeadNavWriter ref="headeNvWriterRef"/>

    <div class="writer-box-content">

      <!-- steps -->
      <StepsWriter :active="1"/>

      <!-- elements -->
      <div class="hidden-xs-only" style="font-weight: 600; font-size: 20px; color: #000000; line-height: 24px;margin: 1rem 0;">
        故事章纲
      </div>
      <div class="writer-box-content-editor">
        <el-input
          @input="changeInput"
          v-if="!isSkeleton"
          :placeholder="storyOutlineTemplate"
          id="msgbox" type="textarea" v-model="storyOutlineFull" ></el-input>
        <LlmSkeleton v-else />
      </div>
    </div>

    <!-- footer -->
    <div class="writer-box-footer" :class="{'button-flex-between': story.articleGenType == 'GEN_BY_IDEA'}">
      <span v-show="story.articleGenType == 'GEN_BY_IDEA' && storyOutlineFulls.length==0"></span>
      <span v-show="story.articleGenType == 'GEN_BY_IDEA' && storyOutlineFulls.length>0">
        <el-select class="custom-select"
            v-model="story.storyOutlineFullId"
            filterable
            size="mini"
            :disabled="isLoading"
            placeholder="版本">
          <el-option
            v-for="(itemVersion, index) in storyOutlineFulls"
            @click.native.stop="contentChange(itemVersion)"
            :key="itemVersion.id"
            :label="'版本 ' + itemVersion.version"
            :value="itemVersion.id"
          />
        </el-select>
      </span>
      <span class="button-group">
        <el-button class="custom-button" :size="$root.buttonSize" type="primary" @click="handleSave(2)" :disabled="storyOutlineFulls.length==0">下一步</el-button>
      </span>
    </div>

  </div>
</template>
<script>
import StepsWriter from '@/components/Steps4OutlineWriter'
import HeadNavWriter from '@/components/HeadNavWriter'
import LlmSkeleton from '@/components/LlmSkeleton'
import * as EnumApi from '@/api/enums'
import * as ArticleApi from '@/api/article'
import * as StoryOutlineFullApi from '@/api/storyoutlinefull'
import sse from '@/utils/llmstream'
import { mapState, mapMutations} from 'vuex'
export default {
  name: 'Article',
  components: {
    LlmSkeleton, HeadNavWriter, StepsWriter
  },
  data() {
    return {
      checked4ElementVersion: false,
      fromType: 'novel',
      enums: [],
      isRequesting: false,
      isLoading: false,
      isSkeleton: false,
    	targetPermit: 'article',
      tableHeight: 100,
      storyOutlinesPrue: [],
      storyOutlineFull: null,
      storyOutlineFulls: [],
      storyOutlineTemplate: `01
xxx

02
xxx

03
xxx

04
xxx

05
xxx

`
    }
  },
  computed: {
  	...mapState(['user', 'story', 'dialogShowCostomContactFlag'])
  },
  mounted() {
    this.handlePageStoryOutlineFull()
    this.handleEnum()
  },
  methods: {
    ...mapMutations(['setStory', 'setDialogShowCostomContactFlag']),
    replaceLineBreaks(str) {
      return str?str.replace(/\\n/g, '\n').replace(/\n/g, '<br>'):"";
    },
    contentChange(item){
      // console.log('contentChange', item.content);
      this.storyOutlineFull = item.content
      this.story.storyOutline = item.content
      this.story.storyOutlineFullId = item.id
      this.setStory(this.story)
      this.handleUpdateStory(item)
    },
    changeInput(event){
      // 更新 outlinefull
      this.handleUpdateOutlineFullSave()
    },
    handleUpdateStory(item) {
      ArticleApi.update({id: this.story.id,
          storyOutlineFullId: item.id,
          storyOutline: item.content}
      ).then(res => {

      });
    },
    handleUpdateOutlineFullSave() {
      this.storyOutlinesPrue = this.$parseContent(this.storyOutlineFull)
      if(this.storyOutlinesPrue.length==0){
        return false
      }
      StoryOutlineFullApi.create({storyId: this.story.id,
          content: this.storyOutlineFull}
      ).then(res => {
          let result = res.data.data;
          // console.log('result=', result);
          // return false
          this.story.storyOutlineFullId = result.id
          this.story.storyOutlineFull = result.content
          this.setStory(this.story)

          this.storyOutlineFulls = []
          this.storyOutlineFulls.push(result)
      });
    },
    handleUpdateOutlineFull() {
      if(this.isLoading){// 正在生成内容
        return false;
      }
      // console.log('handleSave storyOutlineFull', this.storyOutlineFull, this.storyOutlineTemplate, this.storyOutlineFull == this.storyOutlineTemplate);
      if (!this.storyOutlineFull || !this.storyOutlineFull.trim() || this.storyOutlineFull == this.storyOutlineTemplate) {
          // this.$message.error('请输入故事大纲');
          console.log('大纲丢失');
          return false;
      }
      this.isRequesting = true
      // 用户修改大纲，自动保存（生成过正文；未生成过
      // console.log('storyOutlinesPrue update -------------- ' + this.storyOutlinesPrue);
      StoryOutlineFullApi.create({storyId: this.story.id,
        content: this.storyOutlineFull, storyOutlineFullId: this.story.storyOutlineFullId, fromType: 'genbyoutline_4_update_outlines',
        storyOutlines: this.storyOutlinesPrue}).then(res => {
          let result = res.data.data;
        this.story.storyOutlineFull = this.storyOutlineFull
        this.storyOutlineFulls = []
        this.storyOutlineFulls.push(result)
        if(this.story.stepNumber < 3){
          this.story.stepNumber = 3
        }
        this.setStory(this.story)
        this.isRequesting = false
      })
    },
    handleUpdateStoryOutlineFullVersions(){//内容变化 更新版本
      StoryOutlineFullApi.page({storyId: this.story.id, size: 100}).then(res => {
        let result = res.data.data;
        this.storyOutlineFulls = result.records;
      })
    },
    handlePageStoryOutlineFull(){
      StoryOutlineFullApi.page({storyId: this.story.id, size: 100}).then(res => {
        let result = res.data.data;
        if(res.data.code == 0){
          if(result.records.length > 0){
            this.storyOutlineFulls = result.records;
            let currentOutlineFull = this.storyOutlineFulls.filter(i=>{return i.id==this.story.storyOutlineFullId})[0];
            if(currentOutlineFull){
              this.storyOutlineFull = currentOutlineFull.content
              this.story.storyOutlineFullId = currentOutlineFull.id
            }else{
              this.story.storyOutlineFullId = this.storyOutlineFulls[result.records.length-1].id
              this.storyOutlineFull = this.storyOutlineFulls[result.records.length-1].content
            }
          }else{
            this.storyOutlineFulls = [];
          }

          this.story.storyOutlineFull = this.storyOutlineFull
          this.setStory(this.story)
        }
      })
    },
    handleEnum() {
      EnumApi.list().then(res => {
        let result = res.data.data;
        this.enums = result
      })
    },
    handleSave(flag) {
      if (!this.storyOutlineFull || !this.storyOutlineFull.trim() || this.storyOutlineFull == this.storyOutlineTemplate) {
          this.$message.error('请输入故事章纲');
          return false;
      }
      if(flag == 0){//by outline 下一步 只有一个版本
        // 避免重复
        setTimeout(() => {this.goNextPage()}, 1000);

      }else if(flag == 1){// by idea : 重新生成
        this.genStoryOutlineFull()
      }else if(flag == 2){// by idea : 下一步 (以当前选择的版本生文)

        this.storyOutlinesPrue = this.$parseContent(this.storyOutlineFull)
        if(this.storyOutlinesPrue.length==0){
          return false
        }

        if(this.story.stepNumber < 3){
          this.story.stepNumber = 3
          this.setStory(this.story)
        }
        this.handleUpdateOutlineFull();

        if(this.isRequesting) {
          this.openFullScreen()
        }else{
          this.goNextPage();
        }
      }else if(flag == 3){//by idea AI生成完之后保存版本
        StoryOutlineFullApi.create({storyId: this.story.id, content: this.storyOutlineFull,
         storyOutlines: this.storyOutlinesPrue, fromType: 'genbyoutline_4_update_outlines'}).then(res => {
          this.story.storyOutlineFull = this.storyOutlineFull
          console.log('genbyoutline2 flag 3')
          this.setStory(this.story)
          this.handlePageStoryOutlineFull(3)
        })
      }
    },
    openFullScreen() {
      const loading = this.$loading({
        lock: true,
        customClass: 'custom-loading',
        text: '正在保存...',
        // spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      const requestingInterval = setInterval(() => {
        console.log('this.isRequesting', this.isRequesting);
        if(this.isRequesting){

        }else{
          loading.close();
          if(requestingInterval){
            clearInterval(requestingInterval)
          }
          this.goNextPage()
        }
      }, 2000);
    },
    goNextPage(){
      this.$router.push('/workspace/genbyoutline3')
    },
    scrollToBottom() {
      const msgbox = document.getElementById("msgbox")
      msgbox.scrollTop = msgbox.scrollHeight;
    },
    handleStoryOutlineFullMsg(msg){
      // console.log('handle msg=', msg);
      this.isSkeleton = false
      this.storyOutlineFull = msg
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    },
    genStoryOutlineFull() {
        console.log('story::outlinefull', this.storyOutlineFull);
        this.isLoading = true
        this.isSkeleton = true
        let params = Object.assign({}, this.story)
        params.articleId = this.story.id
        // sse
        let workflowId = "1805227802388316162"
        sse(workflowId, params, this.handleStoryOutlineFullMsg).then((result)=>{
          // console.log('result', result);
          //AI结束，处理逻辑
          this.isLoading = false;
          this.isSkeleton = false;
          this.storyOutlineFull = result
          this.story.storyOutline = result
          this.setStory(this.story)
          this.$refs.headeNvWriterRef.syncUser()
          // 保存版本
          this.handleSave(3)
        }).catch(error => {
          // 处理错误
          console.error(error);
          let currentOutlineFull = this.storyOutlineFulls.filter(i=>{return i.id==this.story.storyOutlineFullId})[0];
          if(currentOutlineFull){
            this.storyOutlineFull = currentOutlineFull.content
          }
          this.isLoading = false;
          this.isSkeleton = false;
        });
    }
  }
}
</script>

<style scoped lang="scss">

</style>
